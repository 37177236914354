const getSelectedBlock = (state) => state.webData.selected.block;
const getSelectedRanch = (state) => state.webData.selected.ranch;
const getSelectedBlocks = (state) => state.webData.selected.blocks;
const getSelectedRanches = (state) => state.webData.selected.ranches;
const getEntitySidebarCollapseState = (state) => state.webData.selected.entitySidebarState;

export {
  getSelectedBlock,
  getSelectedBlocks,
  getSelectedRanch,
  getSelectedRanches,
  getEntitySidebarCollapseState,
};
