import * as sensorApi from './sensors';
import * as farmApi from './farm';
import * as userApi from './user';
import * as controlApi from './control';

export default {
  farmApi,
  userApi,
  sensorApi,
  controlApi,
};
