import useSensor from './sensor/hooks/useSensor';
import useEntity from './farm/hooks/useEntity';
import useRanch from './farm/hooks/useRanch';
import useBlock, { useBlockNames } from './farm/hooks/useBlock';

export default {
  useSensor,
  useBlock,
  useRanch,
  useEntity,
  useBlockNames,
};
