
import React from 'react';
import {
  Menu,
  Dropdown,
  Button,
} from 'antd';

import { useDispatch } from 'react-redux';

import { constants } from 'farmx-api';
import { actions } from 'farmx-redux-core';

import { Link } from 'react-router-dom';

const { loadCavalierStatsSingle } = actions;

export class CavalierActionCellRenderer extends React.Component {
  render () {
    return (
      <CavalierActionMenu
        sensorIdentifier={this.props.data.identifier}
        sensorId={this.props.data.id}
      />
    )
  }
}

function CavalierActionMenu(props) {
  const {
    sensorIdentifier,
    sensorId,
  } = props;

  const dispatch = useDispatch();

  function refreshData() {
    dispatch(loadCavalierStatsSingle({ type: 'cavalier', identifier: sensorIdentifier }));
  }

  function actionMenu() {
    return (
      <Menu>
        <Menu.Item key="view">
          <Link to={`/detail/cavalier/${sensorIdentifier}`}>View Detail</Link>
        </Menu.Item>
        <Menu.Item key="edit">
          <a href={`${constants.ADMIN_URL}/sensor_network/cavaliernode/${sensorId}/`} target="_blank" rel="noopener noreferrer">Edit</a>
        </Menu.Item>
        <Menu.Item key="create-note">
          <Link to={`/notes/create/?sensorType=cavalier&sensorIdentifier=${sensorIdentifier}`}>Create Note</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="refresh" onClick={refreshData}>
          Refresh Data
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Dropdown overlay={actionMenu}>
      <Button type="primary" style={{ border: 'none', width: '100%', height: '100%'}}>Actions</Button>
    </Dropdown>
  );
}
