import { setActions } from './slices/selected';

const {
  setSelectedBlock, setSelectedRanch, setSelectedBlocks, setSelectedRanches,
  setEntitySidebarCollapseState,
} = setActions;
export {
  setSelectedBlock, setSelectedRanch, setSelectedBlocks, setSelectedRanches,
  setEntitySidebarCollapseState,
};
