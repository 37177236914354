import {
  createSlice,
} from '@reduxjs/toolkit';

const name = 'selected';

const selectedSlice = createSlice({
  name,
  initialState: {
    blocks: [], ranches: [], block: [], ranch: [], entitySidebarState: true,
  },
  reducers: {
    setSelectedBlock: (state, data) => { state.block = data; },
    setSelectedRanch: (state, data) => { state.ranch = data; },
    setSelectedBlocks: (state, data) => { state.blocks = data; },
    setSelectedRanches: (state, data) => { state.ranches = data; },
    setEntitySidebarCollapseState: (state, data) => { state.entitySidebarState = data; },
  },
});

const setActions = selectedSlice.actions;

export { setActions };

export default selectedSlice.reducer;
