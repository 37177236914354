import { loadBlockList } from './blocks';
import { loadRanchList, loadRanchDetail } from './ranches';
import { loadEntityList } from './entities';

const loadFarmData = () => (dispatch) => {
  dispatch(loadBlockList());
  dispatch(loadRanchList());
  dispatch(loadEntityList());
};

export {
  loadBlockList,
  loadRanchList,
  loadEntityList,
  loadRanchDetail,
  loadFarmData,
};
