// TODO: this file is duplicated between both the RN and web repos

import axios from 'axios';
// import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import constants from "./constants";

axios.defaults.baseURL = constants.API_URL;
let refreshTimer = null;
const REFRESH_BUFFER_SECONDS = 60;
const MAX_REFRESH_SECONDS = 3600;
// const info = { path: '/', domain: window.location.hostname, sameSite: 'lax' };
// Some good resources:
// authorize and refresh token using saga:
// https://stackoverflow.com/questions/34930735/pros-cons-of-using-redux-saga-with-es6-generators-vs-redux-thunk-with-es2017-asy
//

const setAuthHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = "JWT " + token;
  // axios.defaults.headers.common["Set-Cookie"] = "HttpOnly;Secure;SameSite=Strict";
  axios.defaults.withCredentials = true;
};

const setToken = async (tokenName, token) => {
  // const cookies = new Cookies();
  // return await cookies.set(tokenName, token, info);
  await localStorage.setItem(tokenName, token);
};

const getToken = async (tokenName) => {
  // const cookies = new Cookies();
  // return await cookies.get(tokenName);
  return await localStorage.getItem(tokenName);
};

const removeToken = async (tokenName) => {
  // const cookies = new Cookies();
  // await cookies.remove(tokenName, info);
  // await cookies.set(tokenName, "removed123", info);
  await localStorage.removeItem(tokenName);
};

const setUserToken = async (token, refreshToken) => {
  await setToken('userToken', token);
  await setToken('refreshToken', refreshToken);
  setAuthHeader(token);
  scheduleRefresh(token);
};

const login = async (username, password) => {
  try {
    const response = await axios.post(
      "/api/token/login/",
      {
        username: username,
        password: password,
      }
    );
    await setUserToken(response.data.access, response.data.refresh);
    return true;
  } catch (error) {
    return false;
  }
};

async function logout() {
    clearTimeout(refreshTimer);
    await removeToken("userToken");
    await removeToken("refreshToken");
    axios.defaults.headers.common['Authorization'] = null;
}

async function refreshToken() {
  // console.log('Refreshing token');
  const token = await getToken('refreshToken');

  if (token == null || token === undefined || token === "") {
    console.debug("No token value set");
    return false;
  }

  try {
    const response = await axios.post(
      "/api/token/refresh/",
      {
        refresh: token,
      }
    );
    await setUserToken(response.data.access, response.data.refresh);
    return true;
  } catch (error) {
    return false;
  }
}

function scheduleRefresh(token) {
  clearTimeout(refreshTimer);

  let decodedToken;
  try {
    decodedToken = jwt_decode(token);
  } catch {
    return false
  }
  const expiration = new Date(decodedToken.exp * 1000);
  const seconds = (expiration - new Date()) / 1000;

  // if in the future, schedule a refresh
  if (seconds > REFRESH_BUFFER_SECONDS) {
    // console.log('Scheduling refresh', seconds - REFRESH_BUFFER_SECONDS);
    let timeout = (seconds - REFRESH_BUFFER_SECONDS);
    // avoid overflow
    if (timeout > MAX_REFRESH_SECONDS) timeout = MAX_REFRESH_SECONDS;
    refreshTimer = setTimeout(refreshToken, timeout * 1000);
    return true;
  } else {
    // console.log("timer expired");
    return false;
  }
}

async function refreshTokenOrSchedule() {
  clearTimeout(refreshTimer);
  const token = await getToken('userToken');

  if (token !== undefined && token !== null) {
    if (!scheduleRefresh(token)) return await refreshToken();
    else return true;
  }
  return false;
}

async function getJWTInfo() {
  const refresh = await refreshTokenOrSchedule();
  if (!refresh) return undefined;

  const token = await getToken('userToken');

  if (token !== undefined && token !== null) {
    setAuthHeader(token);
    try {
      return jwt_decode(token);
    } catch {
      return undefined;
    }
  }

  return undefined;
}

async function isAdmin() {
  const userInfo = await getJWTInfo();
  return userInfo && userInfo.admin;
}

function useAuth() {
  refreshTokenOrSchedule();
  const token = localStorage.getItem('userToken');
  if (token !== undefined && token !== null) {
    setAuthHeader(token);
    try {
      const decodedToken = jwt_decode(token);
      const expiration = new Date(decodedToken.exp * 1000);

      if (!decodedToken || !decodedToken.user_id || expiration <= new Date()) {
        return undefined;
      }

      return decodedToken;
    } catch {
      return undefined;
    }
  }
}

export default {
  login,
  logout,
  getUserInfo: getJWTInfo,
  isAdmin,
  useAuth,
};
