import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ChartComponent } from 'farmx-web-ui';
import Moment from 'moment';

import { sensorApi, helpers } from 'farmx-api';
import { actions, selectors } from 'farmx-redux-core';

const { fieldToHeader } = helpers;
const { loadSensorData } = sensorApi;
const { loadSensorDetail } = actions;
const { selectSensor } = selectors;

export default function SensorDataChart(props) {
  const {
    sensor: sensorProps,
    variables,
    startDate,
    endDate,
    mergeAxes,
    compact,
  } = props;

  const { type, identifier, id } = sensorProps;
  const sensor = useSelector((state) => selectSensor(state, type, identifier));
  const dispatch = useDispatch();

  const { id: sensorId } = sensor || {};
  const chartRef = useRef(React.createRef());
  const [data, setData] = useState(null);

  const validInput = !!(
    sensor
    && sensorId
    && type
    && variables
    && variables.length
    && startDate
    && endDate
  );

  // load initial data
  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorDetail({ type, identifier }));
    }
  }, [dispatch, type, identifier]);

  // update data when config changes
  useEffect(() => {
    function refreshData() {
      const chartObj = chartRef.current.highchartsComponent.current.chart;
      if (!validInput) {
        if (chartObj) chartObj.hideLoading();
        setData(null);
        return;
      }
      if (chartObj) chartObj.showLoading();
      loadSensorData(type, sensorId, variables, startDate, endDate).then((response) => {
        if (response && response.status === 200) {
          setData(response.data);
        }
        if (chartObj) chartObj.hideLoading();
      });
    }
    refreshData();
  }, [type, sensorId, startDate, endDate, chartRef, validInput, variables]);

  function buildConfig(seriesData) {
    if (!seriesData) return {};

    const yAxisByUnit = {};
    const allSeries = Object.entries(seriesData).map(([key, value]) => {
      if (!value.length) return undefined;
      const chartData = value[0];
      if (!chartData.data.length) return undefined;

      const { data } = chartData;

      // detect values as strings and convert
      if (typeof data[0][1] === 'string') {
        data.map((datum) => [datum[0], parseFloat(datum[1])]);
      }

      // if int, dont set decimal places, otherwise set to 2
      const isFloat = data[0][1] % 1 !== 0;
      const decimals = isFloat ? 2 : undefined;

      const chartUnits = chartData.units || '';
      const axisKey = mergeAxes ? chartUnits : chartUnits + key;
      const regex = /&deg;/g;
      const units = chartUnits.replace(regex, 'º');
      const unitsStr = ` (${units})`;
      const variableName = fieldToHeader(key);
      const legendTitle = variableName + unitsStr;

      let yAxis = yAxisByUnit[axisKey];
      if (!yAxis) {
        const axisTitle = variableName + unitsStr;
        yAxis = {
          title: {
            text: axisTitle,
          },
          id: axisKey,
          opposite: !compact,
          min: parseFloat(chartData.min),
          max: parseFloat(chartData.max),
        };
        yAxisByUnit[axisKey] = yAxis;
      } else {
        yAxis.title.text = units;
        yAxis.min = Math.min(yAxis.min, chartData.min);
        yAxis.max = Math.max(yAxis.max, chartData.max);
      }

      if (compact) {
        yAxis.title = null;
        yAxis.labels = {
          x: 0,
          y: -2,
          align: 'left',
        };
      }

      return {
        name: legendTitle,
        type: 'line',
        data,
        yAxis: axisKey,
        tooltip: {
          valueDecimals: decimals,
        },
      };
    });
    return {
      yAxis: Object.values(yAxisByUnit),
      series: allSeries.filter(Boolean),
    };
  }

  const config = buildConfig(data);
  const xAxisLabels = compact
    ? {
      y: 10,
      x: 1,
      align: 'left',
    } : {};

  const spacing = compact
    ? [1, 1, 1, 1] : [10, 10, 15, 10];

  return (
    <ChartComponent
      ref={chartRef}
      options={{
        legend: {
          enabled: !compact,
        },
        chart: {
          zoomType: 'xy',
          spacing,
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          gridLineWidth: 1,
          labels: xAxisLabels,
          alternateGridColor: 'rgba(200,200,200,0.1)',
        },
        ...config,
      }}
    />
  );
}

SensorDataChart.propTypes = {
  compact: PropTypes.bool,
  variables: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  mergeAxes: PropTypes.bool,
};

SensorDataChart.defaultProps = {
  compact: false,
  variables: [],
  startDate: null,
  endDate: null,
  sensor: null,
  mergeAxes: false,
};
