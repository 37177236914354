import React from 'react';

export default function Home() {
  return (
    <div className="column">
      <div className="message-container">
        <h2>This is the new FarmX Admin site</h2>
        <h3>Known Issues:</h3>
        <ul>
          <li>No copy metadata action</li>
        </ul>
        <p>
          <span>If you find an issue that is not listed above, please </span>
          <a href="https://farmx1.atlassian.net/secure/CreateIssue.jspa?pid=10400">open a JIRA ticket</a>
          <span> in the FF project and assign to Robin</span>
        </p>

        <h2>Useful Links</h2>

        <p><a href="https://farmx1.atlassian.net/secure/CreateIssue.jspa">Create a JIRA ticket</a></p>

        <p>JIRA dashboards:</p>
        <ul>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10213#">Weekly Dashboard</a></li>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10214#">Self Triage Dashboard</a></li>
        </ul>
        <p>Field Ops dashboards:</p>
        <ul>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10211#">Field Ops Dashboard</a></li>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10212#">Ops Next Week Dashboard</a></li>
        </ul>
      </div>
    </div>
  );
}
