import Home from './Home';
import { MapPage, MapByRanchIdPage } from '../map';
import { GraphPage, MultiGraphPage } from '../graph';
import { SettingsPage } from '../settings';
import { SearchPage } from '../search';
import {
  SensorDetailPage,
  SensorRemovePage,
  SensorReplacePage,
  SensorNotesPage,
  SensorImagesPage,
  CreateNotePage,
  SensorErrorsPage,
} from '../sensor';
import {
  GatewayStatsPage,
  CavalierStatsPage,
  SensorStatsPage,
} from '../table';

const SENSOR_TYPE_REGEX = '[A-Za-z_]+';
const SENSOR_IDENTIFIER_REGEX = '[A-Za-z0-9:_.]+';

export const ROUTE_KEYS = {
  ROOT: '/',
  MAP_ROOT: '/map',
  MAP_PARTIAL: '/map/:sensorType(gateway)',
  MAP: '/map/:sensorType(gateway)/:sensorIdentifier([A-Za-z0-9:_.]+)',
  MAP_BY_RANCH_ID: '/map/ranch/:ranch_id',
  SETTINGS: '/settings',
  GATEWAY_STATS: '/manage/gateway',
  CAVALIER_STATS: '/manage/cavalier',
  SENSOR_STATS: '/manage/sensor',
  // GRAPH: `/graph/:sensorType(${SENSOR_TYPE_REGEX})/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  GRAPH: '/manage/graph',
  MULTI_GRAPH: '/graph',
  SENSOR_DETAIL: `/detail/:sensorType(${SENSOR_TYPE_REGEX})/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  SENSOR_ERRORS: `/errors/:sensorType(cavalier)/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  SENSOR_REPLACE: '/sensor/replace/',
  SENSOR_REMOVE: '/sensor/remove/',
  MANAGE_NOTES: '/manage/notes/',
  MANAGE_IMAGES: '/manage/images/',
  NOTE_CREATE: '/notes/create',
  SEARCH: '/search',
};

export const ROUTES = {
  ROOT: {
    component: Home,
    exact: true,
    key: ROUTE_KEYS.ROOT,
    path: ROUTE_KEYS.ROOT,
  },
  SETTINGS: {
    component: SettingsPage,
    exact: true,
    key: ROUTE_KEYS.SETTINGS,
    path: ROUTE_KEYS.SETTINGS,
  },
  SEARCH: {
    component: SearchPage,
    exact: true,
    key: ROUTE_KEYS.SEARCH,
    path: ROUTE_KEYS.SEARCH,
  },
  GATEWAY_STATS: {
    component: GatewayStatsPage,
    exact: true,
    key: ROUTE_KEYS.GATEWAY_STATS,
    path: ROUTE_KEYS.GATEWAY_STATS,
  },
  CAVALIER_STATS: {
    component: CavalierStatsPage,
    exact: true,
    key: ROUTE_KEYS.CAVALIER_STATS,
    path: ROUTE_KEYS.CAVALIER_STATS,
  },
  SENSOR_STATS: {
    component: SensorStatsPage,
    exact: true,
    key: ROUTE_KEYS.SENSOR_STATS,
    path: ROUTE_KEYS.SENSOR_STATS,
  },
  MAP_ROOT: {
    exact: true,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP_ROOT,
  },
  MAP_PARTIAL: {
    exact: true,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP_PARTIAL,
  },
  MAP: {
    exact: false,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP,
  },
  MAP_BY_RANCH_ID: {
    component: MapByRanchIdPage,
    exact: true,
    key: ROUTE_KEYS.MAP_BY_RANCH_ID,
    path: ROUTE_KEYS.MAP_BY_RANCH_ID,
  },
  GRAPH: {
    exact: false,
    component: GraphPage,
    key: ROUTE_KEYS.GRAPH,
    path: ROUTE_KEYS.GRAPH,
  },
  MULTI_GRAPH: {
    exact: false,
    component: MultiGraphPage,
    key: ROUTE_KEYS.MULTI_GRAPH,
    path: ROUTE_KEYS.MULTI_GRAPH,
  },
  SENSOR_DETAIL: {
    exact: true,
    component: SensorDetailPage,
    key: ROUTE_KEYS.SENSOR_DETAIL,
    path: ROUTE_KEYS.SENSOR_DETAIL,
  },
  SENSOR_ERRORS: {
    exact: true,
    component: SensorErrorsPage,
    key: ROUTE_KEYS.SENSOR_ERRORS,
    path: ROUTE_KEYS.SENSOR_ERRORS,
  },
  SENSOR_REPLACE: {
    exact: true,
    component: SensorReplacePage,
    key: ROUTE_KEYS.SENSOR_REPLACE,
    path: ROUTE_KEYS.SENSOR_REPLACE,
  },
  SENSOR_REMOVE: {
    exact: true,
    component: SensorRemovePage,
    key: ROUTE_KEYS.SENSOR_REMOVE,
    path: ROUTE_KEYS.SENSOR_REMOVE,
  },
  MANAGE_NOTES: {
    exact: true,
    component: SensorNotesPage,
    key: ROUTE_KEYS.MANAGE_NOTES,
    path: ROUTE_KEYS.MANAGE_NOTES,
  },
  MANAGE_IMAGES: {
    exact: true,
    component: SensorImagesPage,
    key: ROUTE_KEYS.MANAGE_IMAGES,
    path: ROUTE_KEYS.MANAGE_IMAGES,
  },
  NOTE_CREATE: {
    exact: true,
    component: CreateNotePage,
    key: ROUTE_KEYS.NOTE_CREATE,
    path: ROUTE_KEYS.NOTE_CREATE,
  },
};
