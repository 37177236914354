import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { helpers } from 'farmx-api';
import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const { selectSensorCapabilities } = selectors;
const { fieldToHeader } = helpers;

export function SensorCapabilitySelect(props) {
  const {
    sensor,
    loadSensorCapabilities,
    disabled,
    capabilities,
    isFetching,
    value,
    defaultValue,
    style,
    onChange,
    mode,
  } = props;

  const { identifier, type } = sensor;

  useEffect(() => {
    if (type && identifier) {
      loadSensorCapabilities({ type, identifier });
    }
  }, [type, identifier, loadSensorCapabilities]);

  const getCapabilityName = (capability) => fieldToHeader(capability);

  return (
    <Select
      disabled={disabled}
      allowClear
      mode={mode}
      defaultValue={defaultValue}
      value={value}
      style={style}
      placeholder={mode === 'multiple' ? 'Select Variables' : 'Select Variable'}
      optionLabelProp="label"
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      notFoundContent={isFetching ? 'loading...' : 'None available'}
      className="aw-sensor-capability-select"
    >
      { capabilities && capabilities.map((capability) => {
        const name = getCapabilityName(capability);
        return (
          <Select.Option key={capability} label={name}>{name}</Select.Option>
        );
      })}
    </Select>
  );
}

SensorCapabilitySelect.propTypes = {
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  loadSensorCapabilities: PropTypes.func,
  disabled: PropTypes.bool,
  capabilities: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isFetching: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  mode: PropTypes.oneOf(['multiple', 'tags']),
};

SensorCapabilitySelect.defaultProps = {
  mode: 'multiple',
  sensor: {},
  loadSensorCapabilities: () => {},
  disabled: false,
  capabilities: [],
  isFetching: false,
  value: undefined,
  defaultValue: undefined,
  style: {},
  onChange: () => {},
};

const mapStateToProps = (state, props) => {
  const { identifier, type } = props.sensor || {};
  const { defaultValue } = props;
  const defaultCapabilities = defaultValue || [];

  const sensorCapabilities = selectSensorCapabilities(state, type, identifier);
  const capabilities = sensorCapabilities || defaultCapabilities;
  return {
    capabilities,
  };
};

const mapDispatchToProps = {
  loadSensorCapabilities: actions.loadSensorCapabilities,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorCapabilitySelect);
