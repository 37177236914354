import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './graph.css';

import {
  PageHeader,
  Button,
  Input,
  DatePicker,
  message,
} from 'antd';
import { ArrowLeftOutlined, SwapOutlined } from '@ant-design/icons';

import { sensorApi } from 'farmx-api';

import moment from 'moment';
import isEqual from 'react-fast-compare';

import { SensorSelect } from 'farmx-web-ui';
import SensorDataChart from './SensorDataChart';
import { SensorCapabilitySelect } from '../sensor';
import SensorBreadCrumb from '../sensor/components/SensorBreadcrumb';

const { getSensorObjectFromSensorKeyId, getSensorKeyId } = sensorApi;

const parseSensorFromParam = (sensorParam) => {
  if (sensorParam === undefined || sensorParam === null) return undefined;
  return getSensorObjectFromSensorKeyId(sensorParam);
};

export class GraphPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const params = new URLSearchParams(location.search);
    const sensorType = params.get('sensorType');
    const sensorIdentifier = params.get('sensorIdentifier');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const variablesStr = params.get('variables');
    const variables = variablesStr ? variablesStr.split(',') : undefined;

    let sensor = {
      type: sensorType,
      identifier: sensorIdentifier,
    };

    if (!sensorIdentifier && !sensorType) {
      const sensorParam = params.get('sensor');
      const parsedSensor = parseSensorFromParam(sensorParam);
      if (parsedSensor) sensor = parsedSensor;
    }

    this.state = {
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      capabilities: variables,
      sensor,
      mergeAxes: false,
    };
  }

  componentDidMount = () => {
    // clear url params on load
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      startDate, endDate, capabilities, sensor,
    } = this.state;
    let urlNeedsUpdate = false;
    if (prevState.startDate !== startDate) urlNeedsUpdate = true;
    if (prevState.endDate !== endDate) urlNeedsUpdate = true;
    if (!isEqual(prevState.capabilities, capabilities)) urlNeedsUpdate = true;
    if (!isEqual(prevState.sensor, sensor)) {
      urlNeedsUpdate = true;
      if (prevState.sensor.type !== sensor.type) {
        this.setState({ capabilities: [] });
      }
    }
    if (urlNeedsUpdate) {
      this.updateUrl();
    }
  }

  updateUrl = () => {
    const { location, history } = this.props;
    const { pathname } = location;
    history.push({
      pathname,
      search: this.getParamStr(),
    });
  }

  onSensorSelect = (sensor) => {
    this.setState({
      sensor,
    });
  }

  onCapabilitiesChange = (capabilities) => {
    this.setState({
      capabilities,
    });
  }

  onDatesChanged = (dates) => {
    this.setState({
      startDate: dates[0],
      endDate: dates[1],
    });
  }

  getParamStr = () => {
    const {
      startDate,
      endDate,
      sensor,
      capabilities,
    } = this.state;
    const params = {};

    // create sensor params
    if (sensor && sensor.type && sensor.id && !sensor.identifier) {
      params.sensor = getSensorKeyId(sensor);
    } else {
      if (sensor && sensor.type) params.sensorType = sensor.type;
      if (sensor && sensor.identifier) params.sensorIdentifier = sensor.identifier;
    }

    if (capabilities && capabilities.length) params.variables = capabilities.join(',');
    if (startDate) params.startDate = startDate.toISOString();
    if (endDate) params.endDate = endDate.toISOString();

    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;
    return paramStr;
  }

  getCurrentUrl = () => {
    const { location: { pathname } } = this.props;
    const { protocol, host } = window.location;
    const paramStr = this.getParamStr();
    return `${protocol}//${host}${pathname}${paramStr}`;
  }

  showPageLink = () => {
    const url = this.getCurrentUrl();
    // addonAfter={<Button onClick={this.copyToClipboard(url))}><Icon type="copy" /></Button>}
    message.open({
      content: <Input addonBefore="Link" value={url} />,
    });
  }

  render() {
    const {
      startDate,
      endDate,
      sensor,
      capabilities,
      mergeAxes,
    } = this.state;
    const { showBack, history } = this.props;
    const { identifier, type, id: sensorId } = sensor;

    return (
      <div className="graph-page">
        <Helmet>
          <title>Graph</title>
        </Helmet>
        <PageHeader
          title="Sensor Graph"
          subTitle={(
            <SensorBreadCrumb
              sensor={sensor}
            />
          )}
          onBack={() => history.goBack()}
          backIcon={
            showBack
              ? <ArrowLeftOutlined /> : false
          }
          extra={[
            <Button
              key="toggle-merge"
              // type="link"
              onClick={() => this.setState({ mergeAxes: !mergeAxes })}
            >
              <SwapOutlined />
              { !mergeAxes ? 'Merge Axes' : 'Unmerge Axes' }
            </Button>,
          ]}
        >
          <div className="flex-input-group">
            <SensorSelect
              style={{ flex: '0 0 350px' }}
              sensorType={type}
              sensorIdentifier={identifier}
              sensorId={sensorId}
              onChange={this.onSensorSelect}
            />
            <SensorCapabilitySelect
              defaultValue={capabilities}
              value={capabilities}
              sensor={sensor}
              style={{ flex: '1 0 150px' }}
              onChange={this.onCapabilitiesChange}
            />
            <DatePicker.RangePicker
              defaultValue={[startDate, endDate]}
              showTime
              onChange={this.onDatesChanged}
            />
          </div>
        </PageHeader>
        <div className="chart-container">
          <SensorDataChart
            sensor={sensor}
            variables={capabilities}
            startDate={startDate}
            endDate={endDate}
            mergeAxes={mergeAxes}
          />
        </div>
      </div>
    );
  }
}

GraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  showBack: PropTypes.bool,
};

GraphPage.defaultProps = {
  history: {
    goBack: () => {},
    push: () => {},
  },
  showBack: false,
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

export default GraphPage;
