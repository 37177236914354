import React from 'react';

import {
  Menu,
  message,
} from 'antd';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { actions } from 'farmx-redux-core';
import { helpers } from 'farmx-api';

const { getSensorParams } = helpers;
const {
  uninstallSensor,
  loadSensorDetail,
  installSensor,
} = actions;

export class SensorActionsMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    }
  }

  runAction = async (actionName, action) => {
    const { sensor } = this.props;
    const sensorParams = getSensorParams(sensor);
    this.setState({ processing: true, });
    const success = await action(sensorParams);
    this.setState({ processing: false, });
    if (success) {
      message.success(`${actionName} succeeded`);
    } else {
      message.error(`${actionName} failed`);
    }
    this.props.loadSensorDetail(sensor);
  }

  installAction = () => {
    this.runAction("Install", this.props.installSensor);
  }

  uninstallAction = () => {
    this.runAction("Uninstall", this.props.uninstallSensor);
  }

  render () {
    const { sensor } = this.props;
    const { processing } = this.state;
    return (
      <Menu selectable={false}>
        <Menu.Item key="install" onClick={this.installAction} disabled={processing || sensor.install_state === 'installed'}>
          Install
        </Menu.Item>
        <Menu.Item key="uninstall" onClick={this.uninstallAction} disabled={processing}>
          Uninstall
        </Menu.Item>
        <Menu.Item key="replace" disabled={processing}>
          <Link to={{ pathname: "/sensor/replace", state: { sensor, showBack: true }}}>
            Replace
          </Link>
        </Menu.Item>
        <Menu.Item key="remove" disabled={processing}>
          <Link to={{ pathname: "/sensor/remove", state: { sensor, showBack: true }}}>
            Remove / Withdraw
          </Link>
        </Menu.Item>
      </Menu>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};  
}

const mapDispatchToProps = {
  installSensor,
  uninstallSensor,
  loadSensorDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorActionsMenu);
