/* eslint-disable */
// @flow
import React from 'react';
import MapView from './MapView';
import { Helmet } from 'react-helmet';
import { sensorApi } from 'farmx-api';
import { Menu, Input, Dropdown, Tabs } from 'antd';
import Icon from '@ant-design/icons';
import { SensorSelect } from 'farmx-web-ui';
import { SensorDetailTable } from '../sensor';

import {
  FaBroadcastTower,
  FaWifi,
  FaMapPin,
  FaList
} from 'react-icons/fa';

import './map.css'

const { loadSensorMapData } = sensorApi;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const offsetHeight = 50;

export default class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
    this.state = {
      sidebarOpen: true,
      loading: false,
      data: null,
      selected: null,
    };
  }

  async componentDidMount() {
    this.loadGatewayData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { sensorIdentifier, sensorType } = this.props.match.params;
    const { sensorIdentifier: prevSensorIdentifier, sensorType: prevSensorType } = prevProps.match.params;
    if (sensorIdentifier !== prevSensorIdentifier || sensorType !== prevSensorType) {
      this.loadGatewayData();
    }
  }

  async loadGatewayData() {
    const { match: { params } } = this.props;
    const { sensorIdentifier, sensorType } = params;

    this.setState({
      data: null,
      selected: null,
    });

    if (sensorType !== 'gateway') return;
    if (!sensorIdentifier) return;

    try {
      this.setState({
        loading: true,
      });
      const response = await loadSensorMapData(sensorType, sensorIdentifier);
      const data = this.restructureData(response.data);
      this.setState({ 
        data,
        loading: false,
      });
    } catch (e) {
        console.log(e);
        this.setState({loading: false});
    }
  }

  restructureData = (data) => {
    let cavaliers = data.cavaliers.features;
    let gateways = data.gateways.features;
    cavaliers = cavaliers.map(sensor => ({
      id: sensor.id,
      ...sensor.properties,
      type: 'cavalier',
      geometry: sensor.geometry,
    }));
    gateways = gateways.map(sensor => ({
      id: sensor.id,
      ...sensor.properties,
      type: 'gateway',
      geometry: sensor.geometry,
    }));
    return [...gateways, ...cavaliers];
  };

  onGlobalSensorSelect = (sensor) => {
    if (!sensor.type)
      this.props.history.push('/map')
    else if (sensor.identifier)
      this.props.history.push(`/map/${sensor.type}/${sensor.identifier}`)
    else
      this.props.history.push(`/map/${sensor.type}/`)
  }

  sensorSelected = (sensorType, sensorIdentifier) => {
    this.setState({
      selected: {
        type: sensorType,
        identifier: sensorIdentifier,
      }
    });
  }

  toggleSidebar = () => {
    this.buttonDOM.blur();
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  }

  getSidebarWidth = () => {
    let width = this.sidebarRef.current ? this.sidebarRef.current.clientWidth : 0;
    width = this.state.sidebarOpen ? width : 0; 
    return width;
  };

  render() {
    const { sensorType, sensorIdentifier } = this.props.match.params;
    const { data, loading, selected } = this.state;

    return (
      <div className="map-page-content">
        <Helmet>
          <title>Map</title>
        </Helmet>
        <div className="map-container">
          <MapView
            paddingLeft={this.getSidebarWidth()}
            offsetWidth={0}
            offsetHeight={offsetHeight}
            selected={this.state.selected}
            data={data}
            handleSelection={ this.sensorSelected }
          />
        </div>
        <div className="pane">
          <div ref={this.sidebarRef} className={`sidebar sidebar-visible ${ this.state.sidebarOpen ? '' : 'sidebar-collapsed' }`}>
            <div className="sidebar-content">
              <div className="sensor-select-container">
                <SensorSelect
                  sensorType={sensorType}
                  sensorIdentifier={sensorIdentifier}
                  onChange={this.onGlobalSensorSelect}
                  sensorTypeConstraint={['gateway']}
                />
              </div>
              <SensorSidebarTabs
                loading={loading}
                data={data}
                sensor={{ type: sensorType, identifier: sensorIdentifier }}
                selected={selected}
                handleSelection={this.sensorSelected}
              />
            </div>
            <div className="sidebar-toggle-button-container">
              <button
                aria-label="Collapse side panel"
                className="sidebar-toggle-button noprint"
                onClick={this.toggleSidebar}
                ref={(buttonDOM) => { this.buttonDOM = buttonDOM; }}>
              </button>
              <span style={{display: 'none'}}>Collapse side panel</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class SensorSidebarTabs extends React.Component {
  render () {
    const { data, loading, selected, handleSelection, sensor } = this.props;
    if (!loading && !data) return (
      <div className="sidebar-loading"></div>
    )
    return (
      <Tabs type="card">
        <TabPane
          tab={
            <span>
              <Icon component={FaMapPin} />
              Sensors
            </span>
          }
          key="1"
        >
          <SearchableSensorDetailList
            loading={loading}
            data={data}
            sensor={sensor}
            selected={selected}
            handleSelection={handleSelection}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon component={FaList} />
              Detail
            </span>
          }
          key="2"
        >
          <SensorDetailSummary sensor={sensor} loading={loading} />
        </TabPane>
      </Tabs>
    )
  }
}

class SensorContextMenu extends React.Component {
  render () {
    return (
      <Menu selectable={false}>
        <Menu.Item key="sensor-manage">
          <a href={`https://map.farmx.co/manage/cavalier?identifier=${this.props.sensor.identifier}`} target='_blank' rel="noopener noreferrer">View Cav Manage Page</a>
        </Menu.Item>
        <Menu.Item key="sensor-detail">View Detail (coming soon)</Menu.Item>
      </Menu>
    )
  }
}

class SensorDetailSummary extends React.Component {
  render() {
    const { loading, sensor } = this.props;
    if (loading) return (
      <div className="sidebar-loading">Loading...</div>
    );

    return (
      <div className="sidebar-inner">
        <div className="sidebar-scrollable">
          <div className="padded inner-content">
            <SensorDetailTable sensor={sensor} />
          </div>
        </div>
      </div>
    )
  }
}

class SearchableSensorDetailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  sanitizeSearchTerm = (searchTerm) => {
    return searchTerm.toLowerCase().replace(/:/g, "");
  };

  filterData = (data, search) => {
    if (!data) return [];
    const searchTerm = search.replace(/\s/g,'');
    if (!searchTerm || !searchTerm.length) return data;
    const sanitizedSearchTerm = this.sanitizeSearchTerm(searchTerm);
    return data.filter(sensor => this.sanitizeSearchTerm(sensor.identifier).includes(sanitizedSearchTerm));
  };

  searchChanged = (event) => {
    const searchTerm = event.target.value;
    this.setState({
      searchTerm,
    });
  };

  render() {
    const { loading, data, handleSelection, selected } = this.props;
    const { searchTerm } = this.state;

    if (loading) return (
      <div className="sidebar-loading">Loading...</div>
    );

    return (
      <div className="sidebar-inner">
        <div className="search-container">
          <Input.Search
            className='sidebar-search'
            placeholder="Search sensors"
            onChange={this.searchChanged}
            allowClear={true}
          />
        </div>
        <div className="sidebar-scrollable">
          <GatewayDetailList
            loading={loading}
            data={this.filterData(data, searchTerm)}
            selected={selected}
            handleSelection={handleSelection}
          />
        </div>
      </div>
    )
  }
}

class GatewayDetailList extends React.Component {

  itemSelected = ( event ) => {
    if (!event.keyPath || !event.keyPath.length > 1) return;
    let sensorType = null;
    const sensorIdentifier = event.key;
    if (event.keyPath[1] === 'gatewayMenu') sensorType = 'gateway';
    if (event.keyPath[1] === 'cavalierMenu') sensorType = 'cavalier';
    this.props.handleSelection(sensorType, sensorIdentifier);
  };

  filterSensorsByType = (sensors, type) => {
    if (!sensors) return [];
    return sensors.filter(sensor => sensor.type === type);
  };
  
  render () {
    const { data, loading } = this.props;
    const cavaliers = this.filterSensorsByType(data, 'cavalier');
    const gateways = this.filterSensorsByType(data, 'gateway');

    if (loading) return (
      <div style={{ padding: '10px' }}>Loading...</div>
    );

    return (
      <Menu
        mode="inline"
        defaultSelectedKeys={[]}
        defaultOpenKeys={['gatewayMenu', 'cavalierMenu']}
        selectedKeys={this.props.selected ? [this.props.selected.identifier] : null}
        style={{ height: '100%' }}
        onClick={this.itemSelected}
      > 
        <SubMenu
          key="gatewayMenu"
          title={
            <span>
              <Icon component={FaBroadcastTower} />
              Gateways
            </span>
          }
        >
          { !gateways || !gateways.length ?
            <Menu.Item disabled={true} key="no-gw">No gateways</Menu.Item> : null
          }
          {gateways.map((value, index) => {
            if(value.geometry.coordinates[0] === 0 && value.geometry.coordinates[1] === 0) {
              return <Menu.Item key={`${value.identifier}`} disabled>{ value.identifier }</Menu.Item>
            }

            return <Menu.Item key={`${value.identifier}`}>{ value.identifier }</Menu.Item>
          })}
        </SubMenu>
        <SubMenu
          key="cavalierMenu"
          title={
            <span>
              <Icon component={FaWifi} />
              Cavaliers
            </span>
          }
        >
          { !cavaliers || !cavaliers.length ?
            <Menu.Item disabled={true} key="no-cav">No cavaliers</Menu.Item> : null
          }
          {cavaliers.map((value, index) => {
            if(value.geometry.coordinates[0] === 0 && value.geometry.coordinates[1] === 0) {
              return <Menu.Item key={`${value.identifier}`} disabled>{ value.identifier }</Menu.Item>
            }
            return (
              <Menu.Item key={`${value.identifier}`}>
                <Dropdown overlay={() => <SensorContextMenu sensor={value} />} trigger={['contextMenu']}>
                  <div>{ value.identifier }</div>
                </Dropdown>
              </Menu.Item>
            )
          })}
        </SubMenu>
      </Menu>
    )
  }
}
