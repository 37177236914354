import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { helpers } from 'farmx-api';
import columnDefs from '../columnDefs';
import './table.css';

const isEqual = require("react-fast-compare");
const { fieldToHeader } = helpers;

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.darkMedia = window.matchMedia('(prefers-color-scheme: dark)');
    this.lightMedia = window.matchMedia('(prefers-color-scheme: light)');

    this.state = {
      columnDefs: this.buildColumns(props.columns),
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      isDark: this.darkMedia.matches,
    }
  }

  darkFn = (e: MediaQueryListEvent) => { this.setState({ isDark: e.matches }) }
  lightFn = (e: MediaQueryListEvent) => { this.setState({ isDark: !e.matches }) }

  componentDidMount () {
    this.darkMedia.addListener(this.darkFn);
    this.lightMedia.addListener(this.lightFn);
  }

  componentWillUnmount () {
    this.darkMedia.removeListener(this.darkFn);
    this.lightMedia.removeListener(this.lightFn);
  }

  componentDidUpdate = (prevProps, prevState) => {
    let needsSort = false;
    let needsFiltering = false;

    if (!isEqual(prevProps.columnsVisible, this.props.columnsVisible)) {
      this.gridColumnApi.setColumnsVisible(this.props.columns, false);
      this.gridColumnApi.setColumnsVisible(this.props.columnsVisible, true);
      this.autoSizeAll(false);
    }

    // update table rowData
    if (!isEqual(prevProps.rowData, this.props.rowData)) {
      needsFiltering = true;
      this.autoSize();
    }

    // handle loading
    if (prevProps.loading !== this.props.loading && this.gridApi) {
      if (this.props.loading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }

    if (prevProps.fullscreen !== this.props.fullscreen) {
      this.autoSize();
    }

    // handle external filters updates
    if (!isEqual(prevProps.filters, this.props.filters)) needsFiltering = true;
    // handle table sorting
    if (!isEqual(prevProps.sort, this.props.sort)) needsSort = true;

    if (needsFiltering) {
      this.setFilters(this.props.filters);
    }
    
    if (needsSort) {
      this.setSort(this.props.sort);
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.setFilters(this.props.filters);
    this.setSort(this.props.sort);
    // this.gridApi.showLoadingOverlay();
    //if (_initialLoad)
    //    this.gridApi.showLoadingOverlay();
    this.autoSize();
  };

  autoSize = () => {
    this.autoSizeAll(false);
    if (this.props.sizeToFit)
      this.sizeToFit();
  }

  buildColumns = (columns) => {
    columns = ['index', ...columns];
    
    return columns.map(value => {
      return {
        field: value,
        headerName: fieldToHeader(value),
        ...columnDefs[value],
      };
    })
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    if (!this.gridColumnApi) return;
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  downloadToCsv = () => {
    this.gridApi.exportDataAsCsv();
  }

  setSort = sort => {
    this.gridApi.setSortModel(sort);
    this.gridApi.onSortChanged();
  }

  onSortChanged = params => {  
    this.updateIndex();
  }

  onCellClicked = params => {};

  updateIndex = () => {
    const params = { force: true };
    this.gridApi.refreshCells(params);
  }

  /*
   * Load filters into from ag grid, and return to callback
   * currently this is called an extra time
   */
  onFilterChanged = params => {
    // console.log("filter model", this.gridApi.getFilterModel());
    const filters = this.gridApi.getFilterModel();
    if (this.props.filtersChangedCallback) {
      this.props.filtersChangedCallback(filters);
    }
    this.updateIndex();
  }

  setFilter = (field, filter) => {
    this.setGridFilter(field, filter);
  }

  setFilters = (filters) => {
    if (!this.gridApi) return;
    if (!filters || isEqual(filters, {})) {
      this.gridApi.setFilterModel(null);
    } else {
      this.gridApi.setFilterModel(filters);
    }
    this.gridApi.onFilterChanged();
  }

  setGridFilter = (field, filter) => {
    const filterInstance = this.gridApi.getFilterInstance(field);
    if (!filterInstance) return;
    filterInstance.setModel(filter);
    return filterInstance;
  }

  // helper methods for displaying row counts

  getVisibleRowsCount = () => {
    if (!this.gridApi) return 0;
    return this.gridApi.getDisplayedRowCount();
  }

  render() {
    const { rowData } = this.props;
    const { isDark } = this.state;
    const agTheme = isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham';
    return (
      <div
        className={ `${agTheme} ag-grid-container` }
      >
        <AgGridReact
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          columnDefs={this.state.columnDefs}
          rowData={rowData}
          defaultColDef={this.state.defaultColDef}
          onGridReady={this.onGridReady}
          onSortChanged={this.onSortChanged}
          onFilterChanged={this.onFilterChanged}
          onCellClicked={this.onCellClicked}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
          frameworkComponents={this.props.frameworkComponents}
          getRowStyle={(params) => {
            if (params.data.errors && params.data.errors.length > 0) {
              return { background: 'rgba(255, 0, 0, .9)' };
            }
          }}
        />
      </div>
    );
  }
}

export default DataTable;
