import React from 'react';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';

import { constants, helpers } from 'farmx-api';

const {
  getSensorPath,
  getSensorUid,
} = helpers;

export const statusCellRenderer = (params) => {
  const status = params.value;
  if (!status) return null;
  let statusText = `${status.total_good} / ${status.total}`;
  if (status.total === 0) statusText = '-';
  const statusHtml = `<a href="/manage/cavalier?gateway=${params.data.address}">${statusText}</a>`;
  return statusHtml;
};

export const dateCellRenderer = (params) => {
  if (params.value === undefined) return null;
  const date = params.value;
  const format = 'h:mma, D MMM YY';
  const dateText = moment(date).format(format);
  const dateHtml = `<div>${dateText}</div>`;
  return dateHtml;
};

export const booleanCellRenderer = (params) => {
  if (params.value === true) {
    return 'yes';
  }
  return 'no';
};

export const locationCellRenderer = (params) => {
  if (!params.data.latitude || !params.data.longitude) return undefined;
  const value = `${params.data.latitude}, ${params.data.longitude}`;
  const gMapsValue = `${params.data.latitude},${params.data.longitude}`;
  return `<a href="https://www.google.com/maps/search/${gMapsValue}" target="_blank">${value}</a>`;
};

export const gatewayLocationCellRenderer = (params) => {
  if (!params.value) return undefined;
  const mapLink = `<a href="/map/gateway/${params.data.identifier}" target="_blank">${params.value}</a>`;
  // const googleMapsLink = '<a href="https://www.google.com/maps/search/' + replaceAll(params.value, " ", "") + '" target="_blank">'+ params.value + '</a>';
  return mapLink;
};

export function GatewayLocationCellRendererComponent(props) {
  const { value, data } = props;
  const mapLink = `/map/gateway/${data.identifier}`;
  return (
    <Link to={mapLink}>
      {value}
    </Link>
  );
}

export const sensorStatsCellRenderer = (params) => {
  // sensor plan
  const hasPlan = params.data.has_plan;
  const sensorType = params.colDef.field.replace('sensor_stats_', '');
  const sensorTypePlanned = sensorType === 'cavalier' ? sensorType : `${sensorType}_sensor`;
  const plannedCount = hasPlan ? params.data[`planned_${sensorTypePlanned}_count`] : 0;

  const status = params.value;
  if (!status) return null;
  let statusText = `${status.online} / ${status.count} / ${plannedCount}`;
  if (status.count === 0 && plannedCount === 0) statusText = '-';

  const status24Field = `${params.colDef.field}_24`;
  const status24 = params.data[status24Field];
  // const diffCount = status.count - status24.count;
  const diffOnline = status.online - status24.online;
  const status24Text = diffOnline; // `${status24.online} / ${status24.count}`;

  if (status.count !== 0 && diffOnline !== 0) statusText = `${statusText} (${status24Text})`;

  const className = 'sensor-stats-link';
  const sensorTypePath = (params.colDef.field === 'sensor_stats_cavalier') ? 'cavalier' : 'sensor';
  const statusHtml = `<a class="${className}" href="/manage/${sensorTypePath}?gateway=${params.data.identifier}">${statusText}</a>`;
  return statusHtml;
};

export const sensorGraphCellRenderer = (params) => {
  const endDate = moment();
  const startDate = moment().subtract(7, 'days');
  let url = `/manage/graph?sensorType=${params.data.sensor_type}&sensorIdentifier=${params.data.identifier}`;
  url += `&startDate=${startDate.toISOString()}`;
  url += `&endDate=${endDate.toISOString()}`;
  return `<a href="${url}" target="_blank">View Graph</a>`;
};

export const powerGraphCellRenderer = (params) => {
  const endDate = moment();
  const startDate = moment().subtract('days', 7);
  let url = `/manage/graph?sensorType=gateway&sensorIdentifier=${params.data.identifier}&variables=battery_voltage,generated_energy_today,consumed_energy_today,charge_controller_current,charge_controller_voltage`;
  url += `&startDate=${startDate.toISOString()}`;
  url += `&endDate=${endDate.toISOString()}`;
  return `<a href="${url}" target="_blank">View Graph</a>`;
};

export const notesCellRenderer = (params) => {
  const sensor = {
    id: params.data.id,
    type: params.data.sensor_type ? params.data.sensor_type : 'gateway',
  };
  const url = `/manage/notes?sensor=${getSensorUid(sensor)}`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const imagesCellRenderer = (params) => {
  const sensor = {
    id: params.data.id,
    type: params.data.sensor_type ? params.data.sensor_type : 'gateway',
  };
  const url = `/manage/images?sensor=${getSensorUid(sensor)}`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const entityCellRenderer = (params) => {
  if (!params.data.entity || params.data.entity === 'None') return null;
  const url = `/manage/sensor?entity=${params.data.entity}`;
  return `<a href="${url}" target="_blank">${params.data.entity}</a>`;
};

export const ranchCellRenderer = (params) => {

};

export const blockCellRenderer = (params) => {

};

export const gatewayCellRenderer = (params) => {
  if (!params.data.gateway || params.data.gateway === 'None') return null;
  const url = `/manage/cavalier?gateway=${params.data.gateway}`;
  return `<a href="${url}" target="_blank">${params.data.gateway}</a>`;
};

export const cavalierCellRenderer = (params) => {
  if (!params.data.cavalier) return null;
  const url = `/manage/cavalier?identifier=${params.data.cavalier}`;
  return `<a href="${url}" target="_blank">${params.data.cavalier}</a>`;
};

export const cavalierSensorsCellRenderer = (params) => {
  if (!params.data.sensors || !params.data.sensors.length) return 0;
  const url = `/manage/sensor?cavalier=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.sensors.length}</a>`;
};

export const editSensorCellRendererHelper = (params, sensorType) => (
  `<a href="/detail/${sensorType}/${params.data.identifier}/" target="_blank">View</a>`
);

export const editSensorCellRendererGateway = (params) => editSensorCellRendererHelper(params, 'gateway');

export const editSensorCellRenderer = (params) => (
  editSensorCellRendererHelper(params, params.data.sensor_type)
);

export const softwareVersionCellRenderer = (params) => {
  if (!params.data.software_versions) return null;
  const key = params.colDef.field.replace('_sw', '');
  return params.data.software_versions[key];
};

export const numCavaliersCellRenderer = (params) => {
  const url = `/manage/cavalier?gateway=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.num_cavaliers}</a>`;
};

export const numSensorsCellRenderer = (params) => {
  const url = `/manage/sensor?gateway=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.num_sensors}</a>`;
};

export const numProvisionedCellRenderer = (params) => {
  const url = `/manage/sensor?gateway=${params.data.identifier}`;
  const text = `${params.data.num_provisioned} / ${params.data.num_sensors}`;
  return `<a href="${url}" target="_blank">${text}</a>`;
};

export const ipAddressCellRenderer = (params) => {
  if (!params.value) return undefined;
  const url = `ssh://farmx@${params.value}:220`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const uptimeCellRenderer = (params) => {
  if (!params.value) return undefined;
  const seconds = parseFloat(params.value);
  const duration = moment.duration(seconds, 'seconds');
  return duration.humanize();
};

export const updateKeysCellRenderer = (params) => 'Click to update';

export const errorsCellRenderer = (params) => {
  // params.$scope.showValidationErrorsModal = _showValidationErrorsModal;
  if (!params.value.length) {
    return '';
  }
  // params.$scope.errors = params.value;
  // return '<a ng-click="showValidationErrorsModal(errors)" target="_blank">View ' + params.value.length + ' Errors</a>';*/
  const url = `/errors/cavalier/${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.value.length}</a>`;
};

export const createNoteCellRenderer = (params) =>
  /*
  params.$scope.showNotesFormModal = _showNotesFormModal;
  params.$scope.sensor = {
      id: params.data.id,
      type: "cavalier",
      identifier: params.data.radio_address,
  }
  return '<a ng-click="showNotesFormModal(sensor)" target="_blank"><i class="fa fa-edit" aria-hidden="true" title="Create Note"></a>';
  */
  null;


export const actionsCellRenderer = (params) =>
  /* params.$scope.showActionsModal = _showActionsModal;
  params.$scope.showNotesFormModal = _showNotesFormModal;
  params.$scope.refreshRow = refreshRow;
  params.$scope.cav = {
      id: params.data.id,
      type: "cavalier",
      identifier: params.data.radio_address,
  }
  params.$scope.sensors = params.data.sensors;
  var editLink = '<a href="/admin/sensor_network/cavaliernode/' + params.data.id + '/" target="_blank">Edit</a>';
  var actionsLink = '<a ng-click="showActionsModal(cav, sensors)" target="_blank"><i class="fa fa-tasks" aria-hidden="true" title="View Actions"></i></a>';
  var createNotesLink = '<a ng-click="showNotesFormModal(cav)" target="_blank"><i class="fa fa-edit" aria-hidden="true" title="Create Note"></i></a>';
  var refreshLink = '<a ng-click="refreshRow(cav)" target="_blank"><i class="fa fa-refresh" aria-hidden="true" title="Refresh Row"></i></a>';
  var spacer = " | ";
  return editLink + spacer + createNotesLink + spacer + actionsLink + spacer + refreshLink; */
  null;


export const imageCellRenderer = (params) => {
  const imageHtml = `<a href='${params.data.image}' target='_blank'><img src='${params.data.image}' style='height: 100px' /></a>`;
  return imageHtml;
};
