import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Dropdown,
  Button,
} from 'antd';
import Icon, { DownOutlined } from '@ant-design/icons';

import {
  FaTimes,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import StatsPage from './StatsPage';

const { loadGatewayStatsList } = actions;
const {
  selectAllGatewayStats,
  selectLoadingGatewayStats,
} = selectors;

const allColumns = [
  'hostname',
  'last_ping',
  'heartbeat_date',
  'epsolar_date',
  'weather_date',

  'sensor_stats_cavalier',
  'sensor_stats_soil',
  'sensor_stats_pressure',
  'sensor_stats_other',

  'num_provisioned',

  'name',
  'identifier',
  'id',
  'ip_address',
  'entity',
  'ranch',
  'block',
  'visible',
  'install_state',
  'gatewayLocation',

  'notes',
  'images',

  'cell_rssi',
  'cell_rssi_min',
  'uptime',
  'last_boot_time',
  'internet_access',
  'update_keys',

  'cavalier_gateway_sw',
  'aquacheck_sw',
  'basestation_utilities_sw',
  'healthcheck_sw',
  'epsolar_sw',
  'weathergw_sw',

  'num_sensors',
  'num_cavaliers',
  'num_weather_stations',

  'cgsn',
  'vendor_product',
  'frame_error_rate',
  'memory_free',
  'memory_total',
  'loadavg_1m',
  'loadavg_5m',
  'loadavg_15m',

  'cpu_freq',
  'cpu_temp',
  'case_temp',
  'phone',

  'power_graph',
  'has_acpower',
  'battery_type',
  'solarpanel_config',
  'battery_temp',
  'battery_voltage',
  'min_battery_volt',
  'max_battery_volt',
  'battery_current',
  'generated_energy_today',
  'consumed_energy_today',
  'charge_controller_current',
  'charge_controller_voltage',
  'health_check',

  'editGateway',
];

const summaryCols = [
  'hostname',
  'last_ping',
  'heartbeat_date',
  'epsolar_date',
  'weather_date',

  'sensor_stats_cavalier',
  'sensor_stats_soil',
  'sensor_stats_pressure',
  'sensor_stats_other',

  'name',
  'identifier',
  'id',
  'ip_address',
  'entity',
  'ranch',
  'block',
  'install_state',
  'gatewayLocation',

  'notes',
  'images',

  'editGateway',
];

const batteryCols = [
  'hostname',
  'battery_temp',
  'battery_voltage',
  'min_battery_volt',
  'max_battery_volt',
  'battery_current',
  'generated_energy_today',
  'consumed_energy_today',
  'charge_controller_current',
  'charge_controller_voltage',
  'has_acpower',
  'battery_type',
  'solarpanel_config',
  'editGateway',
];

const stationHealthCols = [
  'hostname',
  'cell_rssi',
  'cell_rssi_min',
  'uptime',
  'last_boot_time',
  'internet_access',

  'frame_error_rate',
  'memory_free',
  'memory_total',
  'loadavg_1m',
  'loadavg_5m',
  'loadavg_15m',

  'cpu_freq',
  'cpu_temp',
  'case_temp',
];

const stationDataCols = [
  'hostname',
  'identifier',
  'ip_address',
  'cgsn',
  'vendor_product',
  'phone',
  'update_keys',
];

class GatewayStatsPage extends Component {
  presetMenu = (
    <Menu>
      <Menu.Item key="summary" onClick={() => this.setColumnsVisible(summaryCols)}>Summary</Menu.Item>
      <Menu.Item key="battery" onClick={() => this.setColumnsVisible(batteryCols)}>Battery Info</Menu.Item>
      <Menu.Item key="health" onClick={() => this.setColumnsVisible(stationHealthCols)}>Health</Menu.Item>
      <Menu.Item key="metadata" onClick={() => this.setColumnsVisible(stationDataCols)}>Metadata</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="all" onClick={() => this.setColumnsVisible(allColumns)}>View All</Menu.Item>
    </Menu>
  )

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    console.error(params.entries());

    /*
    Code below replaced this:
    for (const [key, value] of params.entries()) {
      urlFilters[key] = {
        filterType: 'text',
        type: 'equals',
        filter: value,
      };
    }
    */
    const urlFilters = Array.from(params.entries(), ([key, value]) => (
      { key, value }
    )).reduce((obj, item) => ({
      ...obj,
      [item.key]: {
        filterType: 'text',
        type: 'equals',
        filter: item.value,
      },
    }), {});

    const defaultFilters = {
      visible: { filterType: 'text', type: 'equals', filter: 'yes' },
      health_check: { filterType: 'text', type: 'equals', filter: 'yes' },
    };

    const filters = Array.from(params).length ? urlFilters : defaultFilters;

    this.state = {
      columnsVisible: allColumns,
      filters,
      sort: [
        { colId: 'entity', sort: 'asc' },
      ],
    };
  }

  setColumnsVisible = (columns) => {
    this.setState({
      columnsVisible: columns,
    });
  };

  clearFilters = () => {
    this.setState({
      filters: {},
      sort: null,
    });
  }

  filtersChanged = (filters) => {
    this.setState({
      filters,
    });
  }

  render() {
    const { columnsVisible, filters, sort } = this.state;
    const {
      lastUpdated, data, loading, loadData,
    } = this.props;
    const columns = allColumns;

    return (
      <StatsPage
        title="Gateway Stats"
        columns={columns}
        columnsVisible={columnsVisible}
        loadData={loadData}
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={filters}
        sort={sort}
        filtersChangedCallback={this.filtersChanged}
        headerContent={(
          <span className="header-btns">
            <Button type="primary" onClick={this.clearFilters} disabled={!Object.keys(filters).length}>
              <Icon component={FaTimes} />
              <span>Clear Filters</span>
            </Button>
            <Dropdown overlay={this.presetMenu}>
              <Button>
                Presets
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          </span>
        )}
      />
    );
  }
}

GatewayStatsPage.propTypes = {
  lastUpdated: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  loadData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

GatewayStatsPage.defaultProps = {
  lastUpdated: null,
  data: null,
  loading: false,
  history: {
    goBack: () => {},
    push: () => {},
  },
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

const mapStateToProps = (state) => {
  const gatewayStats = selectAllGatewayStats(state);
  const gatewayStatsLoading = selectLoadingGatewayStats(state);
  const { loading, lastUpdated } = gatewayStatsLoading;
  return {
    loading,
    lastUpdated,
    data: gatewayStats,
  };
};

const mapDispatchToProps = {
  loadData: loadGatewayStatsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayStatsPage);
