import { combineReducers } from 'redux';
import sensorsData from './sensor/reducers';
import farmData from './farm/reducers';
import controlData from './control/reducers';
import webData from './web/reducers';

const appReducer = combineReducers({
  sensorsData,
  farmData,
  controlData,
  webData,
});

const initialState = {};

function rootReducer(state = initialState, action) {
  return appReducer(state, action);
}

export default rootReducer;
