import axios from 'axios';
import constants from '../constants';

axios.defaults.baseURL = constants.API_URL;

export const getSensorKey = (sensor) => {
  if (sensor.identifier === null || sensor.identifier === undefined) return undefined;
  if (sensor.type === null || sensor.type === undefined) return undefined;
  return `${sensor.type}/${sensor.identifier}`;
};

export const getSensorKeyId = (sensor) => {
  if (sensor.id === null || sensor.id === undefined) return undefined;
  if (sensor.type === null || sensor.type === undefined) return undefined;
  return `${sensor.type}:${sensor.id}`;
};

export const getSensorByTypeId = (sensor, sensors) => {
  // TODO: error checking
  const key = getSensorKey(sensor);
  if (key === undefined) return undefined;
  return sensors.items[key];
};

export const getSensorTempKey = (sensor) => {
  return `tmp/${sensor.type}/${sensor.identifier}`;
};

export const getBaseIdentifier = (identifier) => {
  const regexes = [
    /_withdrawn/gi,
    /_removed/gi,
    /_deleting/gi,
  ];
  if (identifier === undefined) return undefined;
  regexes.forEach(regex => identifier = identifier.replace(regex, ''));
  return identifier;
}

export const loadSensors = async (installed) => await axios.get('/api/sensors/all/');

export const loadGatewayMapData = async (gatewayId) => {
  return await axios.get(`/api/map/gateway/${gatewayId}/`);
}

export const loadSensorMapData = async (sensorType, sensorIdentifier) => {
  return await axios.get(`/api/map/sensor/${sensorType}/${sensorIdentifier}/`);
}

// sensor stats apis
export const fetchGatewayStatsList = async () => axios.get('/api/stats/gateway/');
export const fetchCavalierStatsList = async () => axios.get('/api/stats/cavalier/');
export const fetchSensorStatsList = async () => axios.get('/api/stats/sensor/');
export const fetchSensorStatsSingle = async (sensorType, sensorIdentifier) => {
  const url = `/api/stats/sensor/single/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`;
  return await axios.get(url);
}

// sensor note apis
export const fetchSensorNotes = async () => axios.get('/api/sensor/notes/');
export const fetchSensorImages = async () => axios.get('/api/sensor/images/');

export const loadSensorStatus = async (sensorType, sensorIdentifier) => {
  const url = `/api/sensor/status/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`;
  return await axios.get(url);
};

export const loadSensorDetail = async (sensorType, sensorIdentifier) => {
  const url = `/api/sensor/detail/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`;
  return await axios.get(url);
};

export const loadSensorDetailById = async (sensorType, sensorId) => {
  const url = `/api/sensor/detail/?sensorType=${sensorType}&sensorId=${sensorId}`;
  return await axios.get(url);
};

export const loadSensorTypes = async () => {
  return await axios.get('/api/sensors/types/');
}

export const loadAvailableSensors = async (sensorType) => {
  return await axios.get(`/api/sensors/available/?sensorType=${sensorType}`);
}

export const loadSensorCapabilities = async (sensorType, sensorIdentifier) => {
  const url = `/api/sensors/capabilities/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`;
  return await axios.get(url);
}

export const loadSensorData = async (sensorType, sensorId, variables, startDate, endDate, cached) => {
  if (cached === undefined) cached = true;
  const sensorString = `${sensorType}:${sensorId}`;
  let url = '/api/data/graph/?sensors=' + sensorString;
  url += '&variable=' + variables.join(',');
  if (startDate != null) {
    url += '&startDate=' + startDate.toISOString();
  }
  if (endDate != null) {
    url += '&endDate=' + endDate.toISOString();
  }
  url += `&cached=${cached}`
  return await axios.get(`${url}/`);
}

export const postSensorNote = async ({ sensorType, sensorIdentifier, action, text, date }) => {
  return await axios.post(
    '/api/sensor/notes/',
    {
      sensorType,
      sensorIdentifier,
      action,
      text,
      date,
    },
  );
};

export const postSensorImage = async ({ sensorType, sensorIdentifier, title, imageData, date }) => {
  return await axios.post(
    '/api/sensor/image/upload/',
    {
      sensorType,
      sensorIdentifier,
      title,
      image: imageData,
      date,
    },
  );
};

export const updateSensorLocation = async ({ sensorType, sensorIdentifier, lat, lng }) => {
  return await axios.post(
    '/api/sensor/update/location/',
    {
      sensorType,
      sensorIdentifier,
      lat,
      lng,
    },
  );
};

export const createSensor = async ({ sensorType, sensorIdentifier }) => {
  return await axios.post(
    '/api/sensor/create/',
    {
      sensorType,
      identifier: sensorIdentifier,
    },
  );
};

export const installSensor = async ({ sensorType, sensorIdentifier }) => {
  return await axios.post(
    '/api/sensor/install/',
    {
      sensorType,
      sensorIdentifier,
    },
  );
};

export const uninstallSensor = async ({ sensorType, sensorIdentifier }) => {
  return await axios.post(
    '/api/sensor/uninstall/',
    {
      sensorType,
      sensorIdentifier,
    },
  );
};

export const removeSensor = async ({ sensorType, sensorIdentifier, newState }) => {
  return await axios.post(
    '/api/sensor/remove/',
    {
      sensorType,
      sensorIdentifier,
      newState,
    },
  );
};

export const removeCavalier = async ({ sensorType, sensorIdentifier, newState }) => {
  return await axios.post(
    '/api/sensor/remove/cavalier/',
    {
      sensorType,
      sensorIdentifier,
      newState,
    },
  );
};


export const replaceSensor = async ({ sensorType, oldSensorIdentifier, newSensorIdentifier }) => {
  return await axios.post(
    '/api/sensor/replace/',
    {
      sensorType,
      oldSensorIdentifier,
      newSensorIdentifier,
    },
  );
};

/*
 * Sensor type api
 */

export const getSensorTypes = () => {
  return Object.keys(constants.SENSOR_TYPES).map(sensorType => sensorType);
};

export const getSensorTypeShortNames = () => {
  return Object.keys(constants.SENSOR_TYPES).map(sensorType => constants.SENSOR_TYPES[sensorType].shortName);
};

export const getSensorTypeForShortName = (shortName) => {
  return Object.keys(constants.SENSOR_TYPES).find(key => constants.SENSOR_TYPES[key].shortName === shortName);
};

export const getShortNameForSensorType = (sensorType) => {
  const sensorTypeInfo = constants.SENSOR_TYPES[sensorType];
  if (!sensorTypeInfo) return undefined;
  return sensorTypeInfo.shortName;
};

export const getNameForSensorType = (sensorType) => {
  const sensorTypeInfo = constants.SENSOR_TYPES[sensorType];
  if (!sensorTypeInfo) return undefined;
  return sensorTypeInfo.name;
};

export const getSensorTypeFromSensorKey = (sensorKey) => {
  const regex = /^[^//]*/;
  const type = sensorKey.match(regex)[0];
  if (type !== '') return type;
  return undefined;
};

export const getSensorObjectFromSensorKey = (sensorKey) => {
  const parts = sensorKey.split('/', 2);
  if (parts.length === 2 && parts[0].length && parts[1].length) return {
    type: parts[0],
    identifier: parts[1],
  }
  return undefined;
};

export const getSensorObjectFromSensorKeyId = (sensorKey) => {
  const parts = sensorKey.split(':', 2);
  if (parts.length === 2 && parts[0].length && parts[1].length) return {
    type: parts[0],
    id: parseInt(parts[1]),
  }
  return undefined;
};

/*
 * Install State
 */

export const isInstallable = (sensor) => {
  if (sensor === undefined || sensor.install_state === undefined) return false;
  return sensor.install_state !== 'installed';
};

/*
 * Data sanitation
 */

export const sanitizeLocation = (location) => {
  return {
    lat: location.lat.toFixed(6),
    lng: location.lng.toFixed(6)
  };
};

export const sanitizeDeviceLocation = (location) => {
  location = {
    lat: location.coords.latitude,
    lng: location.coords.longitude,
  }
  return sanitizeLocation(location);
};
