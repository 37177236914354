import React, { Component } from 'react';
import {
  PageHeader,
  Layout,
  Button,
  Tag,
} from 'antd';

import Moment from 'react-moment';

import {
  FaDownload,
  FaSyncAlt,
  FaExpand,
  FaTimes,
} from 'react-icons/fa';

import DataTable from './DataTable';

import './table.css';

class StatsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount = async () => {
    this.getData();
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    if (event.keyCode === 27) {  // esc pressed
      const { fullscreen } = this.state;
      if (fullscreen) {
        this.setState({
          fullscreen: false,
        });
      }
    }
  }

  refresh = () => {
    this.getData();
  }

  getData = async () => {
    if (!this.props.loadData) return;
    this.props.loadData();
  }

  download = () => {
    this.refs.table.downloadToCsv();
  }

  getTotalRowsCount = () => {
    if (!this.props.data) return 0;
    return this.props.data.length;
  }

  getRowsCountDescription = () => {
    if (!this.refs.table) return null;
    return `${this.refs.table.getVisibleRowsCount()} of ${this.getTotalRowsCount()}`;
  }

  renderCountsSummary = () => {
    const { data } = this.props;
    if (!data || !data.length) return null;
    return (
      <Tag key="count-summary" className="detail-tag">
        Viewing { this.getRowsCountDescription() }
      </Tag>
    )
  }

  renderRefreshTime = () => {
    const { loading, lastUpdated } = this.props;
    const message = (loading || lastUpdated === null) ? "loading..." : (
      <span>Updated <Moment fromNow>{lastUpdated}</Moment></span>
    );
    return (
      <Tag key="last-update" className="detail-tag">
        { message }
      </Tag>
    )
  }

  render() {
    const { fullscreen } = this.state;
    const {
      columns,
      columnsVisible,
      filters,
      sort,
      filtersChangedCallback,
      extra = [],
      data,
      loading,
    } = this.props;
    return (
      <Layout className="stats-page">
        <PageHeader
          title={[
            this.props.title,
            <Button key="refresh" type="link" onClick={this.refresh} disabled={loading}><FaSyncAlt className={loading ? "fa-spin" : ""} /></Button>,
            this.renderRefreshTime(),
          ]}
          extra={[
            // <Statistic title="Viewing" value={this.getRowsCountDescription()} />,
            ...extra,
            <Button key="download" onClick={this.download} disabled={loading}><FaDownload /></Button>,
            <Button key="fullscreen" onClick={() => this.setState({ fullscreen: true })} type="primary"><FaExpand /></Button>,
          ]}
        >
          { this.props.headerContent }
          { this.renderCountsSummary() }
        </PageHeader>
        <div className={`data-table-cont ${fullscreen ? "fullscreen" : ""}`}>
          <DataTable
            ref={"table"}
            loading={loading}
            fullscreen={fullscreen}
            columns={columns}
            columnsVisible={columnsVisible}
            rowData={data}
            filters={filters}
            sort={sort}
            filtersChangedCallback={filtersChangedCallback}
            frameworkComponents={this.props.frameworkComponents}
            sizeToFit={this.props.sizeToFit}
          />
          { fullscreen ? <Button
              className="exit-fullscreen-button"
              key="exit-fullscreen"
              onClick={() => this.setState({ fullscreen: false })}
              type="link"
            > 
              <FaTimes size={20} />
            </Button> : null
          }
        </div>
      </Layout>
    )
  }
}

export default StatsPage;