import React from 'react';
import { Transfer, Table } from 'antd';
import difference from 'lodash/difference';

const leftTableColumns = [
  {
    dataIndex: 'entity',
    title: 'Entity',
    render: entity => entity ? entity.name : null,
  },
  {
    dataIndex: 'name',
    title: 'Ranch',
  },
];
const rightTableColumns = leftTableColumns;

const TableTransfer = ({ leftColumns, rightColumns, loading, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          // pagination={{ position: 'top' }}
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default class RanchTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [],
      disabled: false,
    };
  }

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.props.handleChange(nextTargetKeys);
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  keyById = (arr) => {
    return arr.map((item) => ({ ...item, key: item.id }))
  };

  sortByName = (arr) => {
    return arr.sort((a, b) => {
      if (!a.entity) return b;
      if (!b.entity) return a;
      return a.entity.name.localeCompare(b.entity.name);
    });
  };

  render () {
    const { selectedKeys } = this.state;
    const { ranches } = this.props;
  
    return (
      <div className="content-container">
        <TableTransfer
          style={{ flex: '1 1', display: 'flex' }}
          listStyle={{ flex: '1 1 200px', display: 'flex', flexDirection: 'column' }}
          loading={this.props.loading}
          dataSource={this.sortByName(this.keyById(ranches))}
          titles={['Available', 'Visible']}
          targetKeys={this.props.targetKeys}
          selectedKeys={selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          showSearch={true}
          filterOption={(inputValue, item) => {
            const inputLower = inputValue.toLowerCase();
            return (
              item.name.toLowerCase().indexOf(inputLower) !== -1 ||
              item.entity.name.toLowerCase().indexOf(inputLower) !== -1
            )
          }}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
      </div>
    )
  }
}
