import { combineReducers } from 'redux';
import entities from './entities';
import ranches from './ranches';
import blocks from './blocks';

const farmReducer = combineReducers({
  entities,
  ranches,
  blocks,
});

export default farmReducer;
