export const isBlank =(value) => {
  if (value === null || value === undefined) return true;
  if (value === "None" || value === "none" || value === "") return true;
  if (value === "NaN" || value === "nan") return true;
  return false;
}

export const getSensorParams = (sensor) => {
  return {
    sensorIdentifier: sensor.identifier,
    sensorType: sensor.type
  }
}

export const getSensorUid = (sensor) => {
  return sensor.type + ':' + sensor.id;
}

export const replaceAll = (str, oldStr, newStr) => {
  let re = new RegExp(oldStr, "g");
  return str.replace(re, newStr);
}

export function sanitizeSearchString(query) {
  return query.toLowerCase().replace(/\s/g, '').replace(/:/g, '').replace(/-/g, '');
}

export const getBreadcrumbTitle = (type, item) => {
  if (item === undefined) return null;
  if (item === null) return `<no ${type}>`;
  if (item.hostname) return item.hostname;
  if (item.name) return item.name;
  if (item.identifier) return `${type}@${item.identifier}`;
  if (item.id) return `${type}:${item.id}`;
  return null;
}

export const getSensorPath = (sensorType) => {
  if (sensorType === "gateway")
    return "gateway";
  if (sensorType === "cavalier")
    return "cavaliernode";
  if (sensorType === "aquacheck_soil")
    return "aquachecksensor";
  if (sensorType === "water_pressure")
    return "pressuredafesensor";
  if (sensorType === "farmx_dendrometer")
    return "dendrometerdafesensor";
  if (sensorType === "farmx_frost")
    return "frostdafesensor";
  if (sensorType === "farmx_infrared")
    return "infrareddafesensor";
  if (sensorType === "water_flow_analog")
    return "waterflowanalog";
  if (sensorType === "weather_station")
    return "weatherstation";
  if (sensorType === "vfd")
    return "vfd";
  if (sensorType === "pixl_soil")
    return "pixlsensor";
  return null;
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const fieldToHeader = (str) => {
  const find = '_';
  const re = new RegExp(find, 'g');
  return toTitleCase(str.replace(re, " "));
}
