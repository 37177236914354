import React from 'react';
import {
  Link,
  withRouter,
} from "react-router-dom";
import { matchPath } from "react-router";
import { ROUTES, ROUTE_KEYS } from './routes';
import { Menu } from 'antd';
import { CollapsableSidebar } from 'farmx-web-ui';

// icon imports
import {
  FaChartLine,
  FaMapMarkedAlt,
  FaStickyNote,
  FaBroadcastTower,
  FaWifi,
  FaMapPin,
  FaList,
  FaHome,
  FaSearch,
} from 'react-icons/fa';

import './sidebar.css';

const { SubMenu } = Menu;

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => {
    return matchPath(location.pathname, route);
  });
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
}

//openKeys={[getSubMatchedKey(location)]}
// see: https://github.com/ant-design/ant-design/issues/17174
/*const getSubMatchedKey = (location) => {
  // return '/manage';
  const matchedKey = Object.values({
    key: '/manage/',
    path: '/manage/',
  }).find((route) => {
    return matchPath(location.pathname, route);
  });
  if (matchedKey === undefined) return undefined;
  return matchedKey.path;
}*/

class Sidebar extends React.Component {

  collapseIfMobile = () => {
    if (this.props.isMobile) this.props.onCollapse();
  }

  render () {
    const { location, collapsed, onCollapse } = this.props;
    return (
      <CollapsableSidebar
        collapsed={collapsed}
        onCollapse={onCollapse}
        location={location}
      >
        <Menu className="nav-menu" theme="dark" selectedKeys={[getMatchedKey(location)]} mode="inline" onClick={this.collapseIfMobile}>
          <Menu.Item key={ROUTE_KEYS.ROOT}>
            <Link to="/">
              <FaHome /><span className="menu-title">Home</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.SEARCH}>
            <Link to="/search">
              <FaSearch /><span className="menu-title">Search</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.MAP_ROOT}>
            <Link to="/map">
              <FaMapMarkedAlt /><span className="menu-title">Map</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.GRAPH}>
            <Link to="/manage/graph">
              <FaChartLine size="15px" /><span className="menu-title">Graph</span>
            </Link>
          </Menu.Item>
          <SubMenu
            className="ant-menu-submenu-open"
            key="/manage"
            title={
              <span>
                <FaList size="15px"/>
                  <span className="menu-title">Stats</span>
              </span>
            }
          >
            <Menu.Item key={ROUTE_KEYS.GATEWAY_STATS}>
              <Link to="/manage/gateway">
                <FaBroadcastTower size="15px" /><span className="menu-title">Gateway</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTE_KEYS.CAVALIER_STATS}>
              <Link to="/manage/cavalier">
                <FaWifi size="15px" /><span className="menu-title">Cavalier</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTE_KEYS.SENSOR_STATS}>
              <Link to="/manage/sensor">
                <FaMapPin size="15px" /><span className="menu-title">Sensor</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={ROUTE_KEYS.MANAGE_NOTES}>
            <Link to="/manage/notes"><FaStickyNote size="15px" /><span className="menu-title">Notes</span></Link>
          </Menu.Item>
        </Menu>
      </CollapsableSidebar>
    )
  }
}

export default withRouter(Sidebar);
