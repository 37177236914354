import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { sensorApi } from 'farmx-api';
import {
  initialState,
  requestList,
  receiveList,
  requestListFailed,
  requestItemByParams,
  requestItemByParamsFailed,
  receiveItem,
  loadList,
  loadByTypeId,
  loadByTypeIdentifier,
} from '../../helpers';

import {
  sensorsAdapter,
  selectSensorsState,
  selectSensor,
  selectSensorById,
} from '../selectors';

const name = 'sensors';

export const loadAllSensors = createAsyncThunk(
  `${name}/loadAll`,
  loadList({
    getData: sensorApi.loadSensors,
    getRequestState: selectSensorsState,
  }),
);

export const loadSensorDetail = createAsyncThunk(
  `${name}/loadSingle`,
  loadByTypeIdentifier({
    getData: sensorApi.loadSensorDetail,
    getRequestState: selectSensor,
  }),
);

export const loadSensorDetailById = createAsyncThunk(
  `${name}/loadSingleById`,
  loadByTypeId({
    getData: sensorApi.loadSensorDetailById,
    getRequestState: selectSensorById,
  }),
);

/*
 * Sensor Actions
 */

const processActionApi = (args, api) => {
  api(args).then((value) => {
    if (value && value.success) {
      return value;
    }
    return null;
  });
};

export const updateSensorLocation = createAsyncThunk(
  `${name}/updateLocation`,
  async (args) => processActionApi(args, sensorApi.updateSensorLocation),
);

export const createSensor = createAsyncThunk(
  `${name}/create`,
  async (args) => processActionApi(args, sensorApi.createSensor),
);

export const installSensor = createAsyncThunk(
  `${name}/install`,
  async (args) => processActionApi(args, sensorApi.installSensor),
);

export const uninstallSensor = createAsyncThunk(
  `${name}/uninstall`,
  async (args) => processActionApi(args, sensorApi.uninstallSensor),
);

export const removeSensor = createAsyncThunk(
  `${name}/remove`,
  async (args) => processActionApi(args, sensorApi.removeSensor),
);

export const replaceSensor = createAsyncThunk(
  `${name}/replace`,
  async (args) => processActionApi(args, sensorApi.replaceSensor),
);

const sensors = createSlice({
  name,
  initialState: sensorsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadAllSensors.pending]: requestList,
    [loadAllSensors.fulfilled]: receiveList(sensorsAdapter),
    [loadAllSensors.rejected]: requestListFailed,
    [loadSensorDetail.pending]: requestItemByParams(sensorsAdapter),
    [loadSensorDetail.fulfilled]: receiveItem(sensorsAdapter),
    [loadSensorDetail.rejected]: requestItemByParamsFailed(sensorsAdapter),
    // TODO: test by Id reducers
    [loadSensorDetailById.pending]: (state, action) => {
      // NOTE: I wasnt able to get the normal selectSensorById selector to work
      const { id, type } = action.meta.arg;
      const allSensors = Object.values(sensorsAdapter.getSelectors().selectEntities(state));
      const item = allSensors.find((sensor) => sensor.type === type && sensor.id === id);
      // in some cases the item wont be found, thats ok
      // but if it does exist, it needs to be updated
      if (item && (item.loading === undefined || item.loading === false)) {
        sensorsAdapter.upsertOne(state, {
          ...item,
          loading: true,
          currentRequestId: action.meta.requestId,
        });
      }
    },
    [loadSensorDetailById.fulfilled]: receiveItem(sensorsAdapter),
    [loadSensorDetailById.rejected]: (state, action) => {
      // NOTE: I wasnt able to get the normal selectSensorById selector to work
      const { id, type } = action.meta.arg;
      const allSensors = Object.values(sensorsAdapter.getSelectors().selectEntities(state));
      const current = allSensors.find((sensor) => sensor.type === type && sensor.id === id);
      if (current) {
        sensorsAdapter.upsertOne(state, {
          ...current,
          loading: false,
          error: action.error,
          currentRequestId: undefined,
        });
      }
    },
    [updateSensorLocation.fulfilled]: (state, action) => {
      const {
        sensorType: type,
        sensorIdentifier: identifier,
        lat,
        lng,
      } = action.meta.arg;
      sensorsAdapter.upsertOne(state, {
        type,
        identifier,
        location: { lat, lng },
      });
    },
    [createSensor.fulfilled]: (state, action) => {
      sensorsAdapter.upsertOne(state, {
        ...action.payload,
      });
    },
    [installSensor.fulfilled]: (state, action) => {
      const {
        sensorType: type,
        sensorIdentifier: identifier,
      } = action.meta.arg;
      sensorsAdapter.upsertOne(state, {
        type,
        identifier,
        install_state: 'installed',
        visible: true,
      });
    },
    [uninstallSensor.fulfilled]: (state, action) => {
      const {
        sensorType: type,
        sensorIdentifier: identifier,
      } = action.meta.arg;
      sensorsAdapter.upsertOne(state, {
        type,
        identifier,
        install_state: 'inventory',
        visible: false,
        location: { lat: 0, lng: 0 },
        name: null,
      });
    },
  },
});

export default sensors.reducer;
