import React, { Component } from 'react';
import StatsPage from './StatsPage';

import {
  Menu,
  Dropdown,
  Button,
} from 'antd';
import Icon, { DownOutlined } from '@ant-design/icons';

import {
  FaTimes,
} from 'react-icons/fa';

import { CavalierActionCellRenderer } from './cellRendererComponents';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const { loadCavalierStatsList } = actions;
const {
  selectAllCavalierStats,
  selectLoadingCavalierStats,
} = selectors;

class CavalierStatsPage extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const urlFilters = {};

    for (const [key, value] of params.entries()) {
      urlFilters[key] = {
        filterType: "text",
        type: "equals",
        filter: value,
      };
    };

    const defaultFilters = {
      "visible": {
        filterType: "text",
        type: "equals",
        filter: "yes",
      }
    };

    const filters = Array.from(params).length ? urlFilters : defaultFilters;

    this.state = {
      filters: filters,
      sort: null,
    };
  }

  filtersChanged = (filters) => {
    this.setState({
      filters: filters,
    });
  }

  setFilterOnline = () => {
    this.setState({
      filters: {
        "offline_for_24hrs": {
          filterType: "text",
          filter: "no",
          type: "equals",
        },
        "visible": {
          filterType: "text",
          filter: "yes",
          type: "equals",
        }
      },
    });
  }

  setFilterOffline = () => {
    this.setState({
      filters: {
        "offline_for_24hrs": {
          filterType: "text",
          filter: "yes",
          type: "equals",
        },
        "visible": {
          filterType: "text",
          filter: "yes",
          type: "equals",
        }
      },
    });
  }

  setFilterVisible = () => {
    this.setState({
      filters: {
        "visible": {
          filterType: "text",
          filter: "yes",
          type: "equals",
        }
      },
    });
  }

  clearFilters = () => {
    this.setState({
      filters: {},
    });
  }

  filterPresetMenu = (
    <Menu>
      <Menu.Item key="offline" onClick={this.setFilterOffline}>Offline</Menu.Item>
      <Menu.Item key="online" onClick={this.setFilterOnline}>Online</Menu.Item>
      <Menu.Item key="visible" onClick={this.setFilterVisible}>Visible</Menu.Item>
    </Menu>
  )

  render () {
    const { filters, sort } = this.state;
    const { lastUpdated, data, loading, loadData } = this.props;
    return (
      <StatsPage
        title="Cavalier Stats"
        loadData={loadData}  
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={filters}
        filtersChangedCallback={this.filtersChanged}
        sort={sort}
        headerContent={
          <span className="header-btns">
            <Button type="primary" onClick={this.clearFilters} disabled={ !Object.keys(filters).length } >
              <Icon component={FaTimes} />
              <span>Clear Filters</span>
            </Button>
            <Dropdown overlay={this.filterPresetMenu}>
              <Button>
                Presets <DownOutlined />
              </Button>
            </Dropdown>
          </span>
        }
        frameworkComponents={{
          cavalierActionsMenu: CavalierActionCellRenderer,
        }}
        columns={[
          'visible',
          'install_state',
          'commissioned',
          'up',
          'offline_for_24hrs',
          'offline_for_2hrs',
          'graph',
          'identifier',
          'entity',
          'ranch',
          'block',
          'gateway',
          'location',
          'fw_revision',
          'hw_revision',
          'data_count',
          'data_latest_date',
          'latest_date_posted',
          'rssi',
          'battery_voltage_min',
          'serial',
          // 'num_sensors',
          'sensors',
          // 'sensor1',
          // 'sensor2',
          'errors',
          'notes',
          'images',
          'maintenance',
          'battery_voltage_max',
          'battery_voltage_med',
          'battery_voltage_mean',
          'battery_voltage_std',
          'battery_temp_min',
          'battery_temp_max',
          'battery_temp_med',
          'battery_temp_mean',
          'battery_temp_std',
          'cavalierActions',
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const cavalierStats = selectAllCavalierStats(state);
  const cavalierStatsLoading = selectLoadingCavalierStats(state);
  const { loading, lastUpdated } = cavalierStatsLoading;
  return {
    loading,
    lastUpdated,
    data: cavalierStats,
  };
};

const mapDispatchToProps = {
  loadData: loadCavalierStatsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CavalierStatsPage);
