import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Input, List, Tag } from 'antd';
import { SensorTypeTag, InstallStateTag } from 'farmx-web-ui';
import { helpers } from 'farmx-api';
// import { throttle, debounce } from "throttle-debounce";
import { actions, selectors } from 'farmx-redux-core';

import pluralize from 'pluralize';
import { Helmet } from 'react-helmet';
import './searchPage.css';

const { loadAllSensors } = actions;
const { selectAllSensors } = selectors;
const { sanitizeSearchString } = helpers;

function SensorListItem({ sensor }) {
  const detailLink = `/detail/${sensor.type}/${sensor.identifier}`;
  return (
    <List.Item
      actions={[<Link to={detailLink}>view</Link>]}
    >  
      <List.Item.Meta
        title={
          <div>
            <span>{sensor.identifier}</span> <Tag>id:{sensor.id}</Tag> <span>{sensor.name}</span>
          </div>
        }
        description={
          <div>
            <InstallStateTag state={sensor.install_state} />
          </div>
        }
      />
      <SensorTypeTag sensorType={sensor.type} />
    </List.Item>
  )
}

const updateUrlQuery = (history, pathname, query) => {
  if (query.length) {
    history.push({
      pathname,
      search: new URLSearchParams({ q: query }).toString(),
    });
  } else {
    history.push({
      pathname,
      search: '',
    });
  }
}

const searchSensors = (sensors, query) => {
  return sensors.filter((sensor) => {
    const searchableText = sanitizeSearchString(`${sensor.identifier} ${sensor.name} ${sensor.type} ${sensor.id}`);
    return searchableText.includes(query);
  });
};

export default function SearchPage() {
  // load search query from url params
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const params = new URLSearchParams(search);
  const urlQuery = params.get('q') || '';
  const [query, setQuery] = useState(urlQuery);

  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(undefined);
  const sensors = useSelector(selectAllSensors);

  const searchAndFilterSensors = useCallback((sensors, query) => {
    const sanitizedQuery = sanitizeSearchString(query);
    setSearching(true);
    if (sanitizedQuery.length) {
      const results = searchSensors(sensors, sanitizedQuery);
      setSearchResults(results);
    } else {
      setSearchResults('');
    }
    setSearching(false);
  }, [setSearchResults, setSearching]);

  // const searchDebounced = useCallback(debounce(300, searchSensors), [searchSensors]);
  // const searchThrottled = useCallback(throttle(300, false, searchSensors, false), [searchSensors]);

  useEffect(() => {
    searchAndFilterSensors(sensors, query);
  }, [sensors, query, searchAndFilterSensors]);

  // set url query as query changes
  useEffect(() => {
    updateUrlQuery(history, pathname, query);
  }, [query, pathname, history]);

  useEffect(() => {
    async function loadSensors() {
      setLoading(true);
      await dispatch(loadAllSensors());
      setLoading(false);
    }
    loadSensors();
  }, [setLoading, dispatch]);

  return (
    <div className="column">
      <Helmet>
        <title>Search</title>
      </Helmet>
      <div className="padded padded-top">
        <Input.Search
          placeholder="Search"
          defaultValue={urlQuery}
          // value={query}
          // onChange={(e) => setQuery(e.target.value)}
          allowClear
          enterButton
          onPressEnter={(e) => setQuery(e.target.value)}
          onSearch={(q) => setQuery(q)}
        />
      </div>
      <div className="resultsHeader">
        { loading || searching || !searchResults ? null :
          <div>{pluralize('result', searchResults.length, true)}</div>
        }
      </div>
      <div className="results">
        { sanitizeSearchString(query).length ? (
            <List
              loading={loading || searching}
              dataSource={searchResults}
              renderItem={item => <SensorListItem sensor={item} />}
              pagination={{ position: 'bottom' }}
            /> 
          ) : null
        }
      </div>
    </div>
  );
}
