import {
  farmAdapter,
} from './farmSlice';

import { selectLoadingState } from '../helpers';
import { selectSensorStatus } from '../sensor/selectors';

const selectEntitiesState = (state) => state.farmData.entities;
const selectRanchesState = (state) => state.farmData.ranches;
const selectBlocksState = (state) => state.farmData.blocks;

const entitySelectors = farmAdapter.getSelectors(selectEntitiesState);
const ranchSelectors = farmAdapter.getSelectors(selectRanchesState);
const blockSelectors = farmAdapter.getSelectors(selectBlocksState);

// Entity Selectors
const selectEntityById = entitySelectors.selectById;
const selectAllEntities = entitySelectors.selectAll;

// Ranch Selectors
const populateRanch = (state, ranch) => (ranch && {
  ...ranch,
  entity: selectEntityById(state, ranch.entity),
});
const selectRanchById = (state, id) => {
  const ranch = ranchSelectors.selectById(state, id);
  return populateRanch(state, ranch);
};
const selectRanchesForEntityId = (state, entityId) => ranchSelectors.selectAll(state).filter(
  (ranch) => ranch.entity === entityId,
).map(
  (ranch) => populateRanch(state, ranch),
);
const selectAllRanches = (state) => ranchSelectors.selectAll(state).map(
  (ranch) => populateRanch(state, ranch),
);

// Block Selectors
const populateBlock = (state, block) => (block && {
  ...block,
  ranch: selectRanchById(state, block.ranch),
});
const selectBlockById = blockSelectors.selectById;
const selectAllBlocks = (state) => blockSelectors.selectAll(state).map(
  (block) => populateBlock(state, block),
);

const selectBlocksForRanchId = (state, ranchId) => blockSelectors.selectAll(state).filter(
  (block) => block.ranch === ranchId,
).map(
  (block) => populateBlock(state, block),
);

// Loading state selectors
const selectEntitiesLoadingState = (state) => selectLoadingState(selectEntitiesState(state));
const selectRanchesLoadingState = (state) => selectLoadingState(selectRanchesState(state));
const selectBlocksLoadingState = (state) => selectLoadingState(selectBlocksState(state));
const selectFarmDataLoadingState = (state) => {
  const entitiesLoadingState = selectEntitiesLoadingState(state);
  const ranchesLoadingState = selectRanchesLoadingState(state);
  const blocksLoadingState = selectBlocksLoadingState(state);
  const loading = (
    entitiesLoadingState.loading
    || ranchesLoadingState.loading
    || blocksLoadingState.loading
  );
  return {
    loading,
    entitiesLoadingState,
    ranchesLoadingState,
    blocksLoadingState,
  };
};

// select block valve status
const selectBlockSensorStatus = (state, blocks) => blocks.map((block) => {
  let vid = null;
  if (block.valve) {
    vid = block.valve.identifier;
  }
  const valveState = selectSensorStatus(state, 'valve', vid);

  let online;
  let loading;
  if (valveState) {
    online = valveState.online;
    loading = valveState.loading;
  }

  let currState;
  let valveScheduledOpen;
  let valveScheduledClose;
  if (valveState && valveState.controlStatus) {
    currState = valveState.controlStatus.state;
  }

  if (valveState && valveState.scheduleStatus) {
    valveScheduledOpen = valveState.scheduleStatus.dateScheduledOpen;
    valveScheduledClose = valveState.scheduleStatus.dateScheduledClose;
  }

  let ovrdStatus = null;
  if (valveState && valveState.overrideStatus) {
    ovrdStatus = valveState.overrideStatus;
  }

  let pid = null;
  if (block.vfd) {
    pid = block.vfd.identifier;
  }
  const pumpState = selectSensorStatus(state, 'vfd', pid);

  let pumpStart;
  let pumpStop;
  if (pumpState && pumpState.scheduleStatus) {
    pumpStart = pumpState.scheduleStatus.dateScheduledStart;
    pumpStop = pumpState.scheduleStatus.dateScheduledStop;
  }

  return {
    blockId: block.id,
    blockName: block.name,
    valveIdentifier: vid,
    valveOnline: online,
    valveCurrentState: currState,
    valveOverrideStatus: ovrdStatus,
    valveControlEnabled: block.control_enabled,
    valveScheduledOpen,
    valveScheduledClose,
    vfdIdentifier: pid,
    pumpStartDate: pumpStart,
    pumpStopDate: pumpStop,
    valveLoading: loading,
  };
});

// select block valve status
const selectPumpSensorStatus = (state, pumps) => pumps.map((pump) => {
  let pid = null;
  if (pump.identifier) {
    pid = pump.identifier;
  }
  const pumpState = selectSensorStatus(state, 'vfd', pid);
  let online;
  let loading;
  if (pumpState) {
    online = pumpState.online;
    loading = pumpState.loading;
  }
  let currState;
  let datePumpStart;
  let datePumpStop;
  if (pumpState && pumpState.controlStatus) {
    currState = pumpState.controlStatus.status;
  }

  if (pumpState && pumpState.scheduleStatus) {
    datePumpStart = pumpState.scheduleStatus.dateScheduledStart;
    datePumpStop = pumpState.scheduleStatus.dateScheduledStop;
  }

  let ovrdStatus = null;
  if (pumpState && pumpState.overrideStatus) {
    ovrdStatus = pumpState.overrideStatus;
  }

  return {
    pumpId: pump.id,
    pumpName: pump.name,
    pumpIdentifier: pid,
    pumpCurrentState: currState,
    pumpOverrideStatus: ovrdStatus,
    pumpStartDate: datePumpStart,
    pumpStopDate: datePumpStop,
    pumpOnline: online,
    pumpLoading: loading,
  };
});

export {
  selectFarmDataLoadingState,
  selectEntitiesLoadingState,
  selectEntityById,
  selectAllEntities,
  selectRanchesLoadingState,
  selectRanchById,
  selectRanchesForEntityId,
  selectAllRanches,
  selectBlocksLoadingState,
  selectBlockById,
  selectAllBlocks,
  selectBlocksForRanchId,
  selectBlockSensorStatus,
  selectPumpSensorStatus,
};
