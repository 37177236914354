import React, { Component } from 'react';
import StatsPage from './StatsPage';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const { loadSensorStatsList } = actions;
const {
  selectAllSensorStats,
  selectLoadingSensorStats,
} = selectors;

export class SensorStatsPage extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const urlFilters = {};

    for (const [key, value] of params.entries()) {
      urlFilters[key] = {
        filterType: "text",
        type: "equals",
        filter: value,
      };
    };

    this.state = {
      filters: urlFilters,
      sort: null,
    };
  }

  filtersChanged = (filters) => {
    this.setState({
      filters: filters,
    });
  }

  render () {
    const { filters, sort } = this.state;
    const { lastUpdated, data, loading, loadData } = this.props;
    return (
      <StatsPage
        title="Sensor Stats"
        loadData={loadData}  
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={ filters }
        filtersChangedCallback={this.filtersChanged}
        sort={ sort }
        columns={[
          'up',
          'id',
          'identifier',
          'name',
          'sensor_type',
          'gateway',
          'cavalier',
          'entity',
          'ranch',
          'block',
          'visible',
          'install_state',
          'provisioned',
          'location',
          'graph',
          'data_count',
          'data_latest_date',
          'latest_date_posted',
          'notes',
          'images',
          'edit',
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const sensorStats = selectAllSensorStats(state);
  const sensorStatsLoading = selectLoadingSensorStats(state);
  const { loading, lastUpdated } = sensorStatsLoading;
  return {
    loading,
    lastUpdated,
    data: sensorStats,
  };
};

const mapDispatchToProps = {
  loadData: loadSensorStatsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorStatsPage);
