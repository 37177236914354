import moment from 'moment';

import {
  formatDateTooltip,
} from './tooltips';

import * as cellRenderers from './cellRenderers';
import * as cellStyles from './cellStyles';

const dateComparator = (valueA, valueB, nodeA, nodeB, isInverted) => moment(valueA) - moment(valueB);

const booleanFilterValueGetter = (params) => {
  if (params.data[params.colDef.field] === true) {
    return 'yes';
  }
  return 'no';
};

const locationValueGetter = (params) => {
  if (!params.data.latitude || !params.data.longitude) return undefined;
  return `${params.data.latitude}, ${params.data.longitude}`;
};

const dateColumnTemplate = {
  comparator: dateComparator,
  tooltipValueGetter: formatDateTooltip,
  cellRenderer: cellRenderers.dateCellRenderer,
};

const booleanColumnTemplate = {
  cellRenderer: cellRenderers.booleanCellRenderer,
  filterValueGetter: booleanFilterValueGetter,
};

const statsColumnTemplate = {
  tooltipValueGetter: (params) => 'View details',
  cellStyle: cellStyles.sensorStatsCellStyle,
  cellRenderer: cellRenderers.sensorStatsCellRenderer,
};

const smallColumnWidth = 55;
const mediumColumnWidth = 70;

export default {
  index: {
    headerName: '#',
    field: 'index',
    cellRenderer: (params) => params.rowIndex + 1,
    suppressMenu: true,
    sortable: false,
    pinned: 'left',
    width: 40,
  },
  id: {
    headerName: 'id',
  },
  hostname: {
    pinned: 'left',
  },
  edit: {
    suppressMenu: true,
    sortable: false,
    pinned: 'right',
    cellRenderer: cellRenderers.editSensorCellRenderer,
    width: smallColumnWidth,
  },
  editGateway: {
    headerName: 'Edit',
    suppressMenu: true,
    sortable: false,
    pinned: 'right',
    cellRenderer: cellRenderers.editSensorCellRendererGateway,
    width: smallColumnWidth,
  },
  identifier: {
    headerName: 'identifier (MAC)',
  },
  data_count: {
    headerName: 'Count',
  },
  // date columns
  data_latest_date: {
    ...dateColumnTemplate,
  },
  latest_date_posted: {
    ...dateColumnTemplate,
  },
  last_ping: {
    ...dateColumnTemplate,
    headerName: 'Last Online',
    cellStyle: cellStyles.pingCellStyle,
  },
  weather_date: {
    ...dateColumnTemplate,
    cellStyle: cellStyles.dataDateCellStyle,
  },
  epsolar_date: {
    ...dateColumnTemplate,
    cellStyle: cellStyles.dataDateCellStyle,
  },
  heartbeat_date: {
    ...dateColumnTemplate,
    cellStyle: cellStyles.dataDateCellStyle,
  },
  date: {
    ...dateColumnTemplate,
  },
  last_boot_time: {
    ...dateColumnTemplate,
  },
  image: {
    cellRenderer: cellRenderers.imageCellRenderer,
  },
  // action columns
  create_note: {
    pinned: 'right',
    cellRenderer: cellRenderers.createNoteCellRenderer,
    width: smallColumnWidth,
    headerName: '+ Note',
    suppressMenu: true,
    sortable: false,
  },
  cavalierActions: {
    headerName: 'Actions',
    pinned: 'right',
    cellRenderer: 'cavalierActionsMenu',
    width: smallColumnWidth,
    suppressMenu: true,
    sortable: false,
    cellStyle: {
      padding: '0px 5px',
    },
  },
  location: {
    cellRenderer: cellRenderers.locationCellRenderer,
    valueGetter: locationValueGetter,
  },
  gatewayLocation: {
    headerName: 'Location',
    cellRenderer: cellRenderers.gatewayLocationCellRenderer,
    valueGetter: locationValueGetter,
  },
  update_keys: {
    cellRenderer: cellRenderers.updateKeysCellRenderer,
  },
  // boolean columns
  visible: {
    ...booleanColumnTemplate,
    width: smallColumnWidth,
    headerName: 'Vis',
  },
  provisioned: {
    ...booleanColumnTemplate,
  },
  weather_online: {
    ...booleanColumnTemplate,
  },
  offline_for_2hrs: {
    ...booleanColumnTemplate,
    width: mediumColumnWidth,
    headerName: 'Off/2',
  },
  offline_for_24hrs: {
    ...booleanColumnTemplate,
    width: mediumColumnWidth,
    headerName: 'Off/24',
  },
  up: {
    ...booleanColumnTemplate,
    width: smallColumnWidth,
  },
  commissioned: {
    ...booleanColumnTemplate,
    width: smallColumnWidth,
    headerName: 'Com',
  },
  maintenance: {
    ...booleanColumnTemplate,
  },
  has_acpower: {
    ...booleanColumnTemplate,
    headerName: 'AC Power',
  },
  health_check: {
    ...booleanColumnTemplate,
  },
  internet_access: {
    ...booleanColumnTemplate,
  },
  // stats columns
  sensor_stats_cavalier: {
    ...statsColumnTemplate,
    headerName: 'Cavaliers',
  },
  sensor_stats_soil: {
    ...statsColumnTemplate,
    headerName: 'Soil Sensors',
  },
  sensor_stats_pressure: {
    ...statsColumnTemplate,
    headerName: 'Pressure Sensors',
  },
  sensor_stats_other: {
    ...statsColumnTemplate,
    headerName: 'Other Sensors',
  },
  // link columns
  errors: {
    filterValueGetter: (params) => {
      const errors = params.value;
      if (!errors) return 0;
      return errors.length;
    },
    cellRenderer: cellRenderers.errorsCellRenderer,
  },
  notes: {
    cellRenderer: cellRenderers.notesCellRenderer,
    width: mediumColumnWidth,
  },
  images: {
    cellRenderer: cellRenderers.imagesCellRenderer,
    width: mediumColumnWidth,
  },
  entity: {
    cellRenderer: cellRenderers.entityCellRenderer,
  },
  ranch: {
    // cellRenderer: cellRenderers.ranchCellRenderer,
  },
  block: {
    // cellRenderer: cellRenderers.blockCellRenderer,
  },
  gateway: {
    cellRenderer: cellRenderers.gatewayCellRenderer,
  },
  cavalier: {
    cellRenderer: cellRenderers.cavalierCellRenderer,
  },
  graph: {
    cellRenderer: cellRenderers.sensorGraphCellRenderer,
    width: mediumColumnWidth,
  },
  power_graph: {
    cellRenderer: cellRenderers.powerGraphCellRenderer,
  },
  // sw versions
  cavalier_gateway_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  aquacheck_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  basestation_utilities_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  healthcheck_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  epsolar_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  weathergw_sw: {
    cellRenderer: cellRenderers.softwareVersionCellRenderer,
  },
  // fw / hw revision
  fw_revision: {
    headerName: 'FW Rev',
  },
  hw_revision: {
    headerName: 'HW Rev',
  },
  // other status
  uptime: {
    cellRenderer: cellRenderers.uptimeCellRenderer,
  },
  ip_address: {
    cellRenderer: cellRenderers.ipAddressCellRenderer,
  },
  num_cavaliers: {
    cellRenderer: cellRenderers.numCavaliersCellRenderer,
  },
  num_sensors: {
    cellRenderer: cellRenderers.numSensorsCellRenderer,
  },
  num_provisioned: {
    cellRenderer: cellRenderers.numProvisionedCellRenderer,
  },
  sensors: {
    cellRenderer: cellRenderers.cavalierSensorsCellRenderer,
    valueGetter: (params) => {
      if (!params.data.sensors) return 0;
      return params.data.sensors.length;
    },
  },
};
