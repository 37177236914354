import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  PageHeader,
  DatePicker,
  Radio,
} from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';

import { SensorGraphForm } from './SensorGraphForm';
import EditableSensorDataChart from './EditableSensorDataChart';
import './graph.css';

const graphSizeOptions = [
  { value: 'sm', label: 'Small' },
  { value: 'md', label: 'Medium' },
  { value: 'lg', label: 'Large' },
];

export function MultiGraphPage(props) {
  const history = useHistory();
  const { showBack } = props;
  const now = moment();
  const [dateRange, setDateRange] = useState([now.clone().subtract(24, 'hours'), now]);
  const [graphs, setGraphs] = useState([
    {
      component: EditableSensorDataChart,
      key: 'abc',
      sensor: {
        type: 'gateway',
        id: 12,
        identifier: 'e0:e5:cf:9e:8f:87',
      },
      variable: 'battery_temp',
    },
    {
      component: EditableSensorDataChart,
      key: '123',
      sensor: {
        type: 'gateway',
        id: 12,
        identifier: 'e0:e5:cf:9e:8f:87',
      },
      variable: 'battery_voltage',
    },
  ]);

  const [graphSize, setGraphSize] = useState('md');

  function onGraphCreate(graphConfig) {
    const config = {
      component: EditableSensorDataChart,
      key: moment(),
      ...graphConfig,
    };
    setGraphs([...graphs, config]);
  }

  function onSizeChange(e) {
    setGraphSize(e.target.value);
  }

  function renderSizeSelect() {
    return (
      <Radio.Group
        options={graphSizeOptions}
        onChange={onSizeChange}
        value={graphSize}
        optionType="button"
      />
    );
  }

  function removeGraph(graphKey) {
    setGraphs(graphs.filter((graph) => graph.key !== graphKey));
  }

  return (
    <div className="graph-page multi-graph-page">
      <Helmet>
        <title>Graph</title>
      </Helmet>
      <PageHeader
        title="Sensor Graph"
        onBack={() => history.goBack()}
        backIcon={
          showBack
            ? <ArrowLeftOutlined /> : false
        }
        extra={renderSizeSelect()}
      />
      <div className="page-body padded">
        <DatePicker.RangePicker
          showTime
          onChange={(d) => setDateRange(d)}
          value={dateRange}
          format="YYYY-MM-DD HH:mm:ss"
          defaultValue={dateRange}
          onOk={null}
          style={{ width: '100%' }}
        />
        <div className="add-graph">
          <SensorGraphForm
            onSubmit={onGraphCreate}
          />
        </div>
        <div className="graph-list">
          {graphs.map((graph) => (
            <graph.component
              key={graph.key}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              variable={graph.variable}
              sensor={graph.sensor}
              size={graphSize}
              deletable
              onDelete={() => removeGraph(graph.key)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

MultiGraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  showBack: PropTypes.bool,
};

MultiGraphPage.defaultProps = {
  history: {
    goBack: () => {},
    push: () => {},
  },
  showBack: false,
};
