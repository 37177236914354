import axios from 'axios';

export const getUserAppConfig = async () => {
  try {
    const response = await axios.get("/api/user/config/app/");
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updateUserAppConfig = async (data) => {
  try {
    const response = await axios.patch("/api/user/config/app/", data);
    return response.data;
  } catch (error) {
    return false;
  }
};